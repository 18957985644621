/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import PolaBanglesHeader from "./PolaBanglesHeader";
import PolaBanglesHeaderLinks from "./PolaBanglesHeaderLinks";
import PolaBanglesStock from "./PolaBanglesStock";
import  PolaBanglesFooter  from "./PolaBanglesFooter";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(aboutUsStyle);

export default function ProductStock() {

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
      <div>
         <Helmet>
                <title>Product-List</title>
                <meta name="description" content="Manufacturer  & exporter of 3 Tone Bangles - Fine Gold Plated Bangles, Micro Dell Bangles, Punching 3Tone Plated Bangles and ... 5MM 3 Tone Micron Plated Bangles.
                  Complement your ensembles with this traditional pola bangle set crafted with a high-polished gold finish. We offer best quality bangles to our customers, with 6 months colour guarantee. Also, we use the finest quality raw material to make the bangles look charming and elegant. We provide bangles with the lowest making charges on order up to 1000 boxes. This is a one-time benefit, can be only availed at the first order. A Red Pola bangle signifies energy and prosperity, while green Pola denotes good luck and fertility and White Pola Bangles symbolizes peace and happiness." />
                <meta name="keywords" content="Floral Bengali Pola Bangle Set, Pair of Pola Bangles, Pola Bangle with a Gold Stamped Pattern, Gold Shankha Bangle Set of 2, Acrylic & Crystal Pola Bangles Set for Women, Brass Gold Plated & Crystal Pola Bangles Set for Women Pack of 4, Acrylic gold plated shakha pola, Acrylic plastic pola bangles, acrylic gold plated bracelet, Gold Pola Bandhano, red and white pola bangles, Maharashtrian pola bangles, Odisha pola bangles, Bengal pola bangles, maroon pola bangles, green pola bangles, gold plated pola bangles, loha Sakha pola, sakha pola,	micro gold plated bangles, die gold plated, gold plated bangles, tricolour, 3 tone Seminario bangles for women, party wear bracelets and earrings, finger rings, watches, 3 Tone Kada • 6 MM Three Tone Plated Bangle • 3 tone Cuff Bracelet • 3 Tone Gold plated Diamond cut bangles,Meena bangles," />
        </Helmet>
            
       <PolaBanglesHeader
        brand="Home"        
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" /> }
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "danger",
        }}
        />  
      <Parallax
        image={require("assets/img/examples/clark-street-merc.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>Product Stock</h1>
              <h4 style={{ color: "white" }}>Here you will get to see our elegant collection of different designs, sizes and colors of bangles. These are our overall products which you can buy online as well as offline.</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <PolaBanglesStock />
      </div>
          <PolaBanglesFooter/>        
    </div>
  );
}

