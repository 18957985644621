/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import PolaBanglesHeader from 'views/MyCompo/PolaBanglesHeader';
import PolaBanglesHeaderLinks from 'views/MyCompo/PolaBanglesHeaderLinks';
import PolaBanglesFooter from "views/MyCompo/PolaBanglesFooter";
// import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import TermsDescription from "views/MyCompo/TermsDescription";
// import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
// import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import aboutUsStyl from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(aboutUsStyl);

export default function TermCondition() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
        <Helmet>
        <title>Terms And Conditions</title>
        <meta
          name="description"
          content="Pola Bangles Terms And Conditions If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are Read the terms and conditions carefully before purchasing the jewellery. By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age "
        />
        <meta name="keywords" content="Pola Bangles Terms And Conditions" />
      </Helmet>
      <PolaBanglesHeader
        brand="Home"
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "danger",
        }}
      />
      <Parallax
        image={require("assets/img/bg2.jpg").default}
        // style={{backgrounColor: "#3c4858"}}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={10}
              sm={10}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 style={{color: "#fff", fontFamily: "Tahoma", fontWeight: "bold"}}>Terms And Condition</h1>
              <h4 style={{color: "#fff"}}>
                
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <TermsDescription />
          {/* <SectionTeam /> */}
          {/* <SectionOffice /> */}
          <br />
        </div>
      </div>
      <PolaBanglesFooter />
    </div>
  );
}