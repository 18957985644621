/*eslint-disable*/
import React from "react";
import { useLocation } from "react-router";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionLatestOffers from "views/EcommercePage/Sections/SectionLatestOffers.js";
import SectionProducts from "views/EcommercePage/Sections/SectionProducts.js";
import SectionBlog from "views/EcommercePage/Sections/SectionBlog.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui icons
import Mail from "@material-ui/icons/Mail";
import PolaBanglesHeader from "./PolaBanglesHeader";
import PolaBanglesHeaderLinks from "./PolaBanglesHeaderLinks";
// import PolaBanglesOffers from "./PolaBanglesOffers";
import PolaBanglesFooter from "./PolaBanglesFooter";
import PolaSizeMMlist from "./PolaSizeMMlist";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(aboutUsStyle);

export default function SizeMMlist() {
  const userSession = JSON.parse(sessionStorage.getItem("sessionData"));

  const search = useLocation().search;
  const catdesc = new URLSearchParams(search).get("catdesc");
  const cat1desc = new URLSearchParams(search).get("cat1desc");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Bangles-Size</title>
        <meta
          name="description"
          content="All sizes and types of Pola Bangles are available online at factory prices, with a range of almost 1400 stylish and trendy designs to suit every woman. pola bangles, also known as shakha Paula, are worn by Bengali brides. These shakha pola bangles are considered to be an essential part of a Bengali wedding. As customs have it, one has to wear a pair of each in both hands."
        />
        <meta
          name="keywords"
          content="Golwire Golwire Small, Golwire Medium,1MM Gold Plated bangles,1.5MM Gold Plated bangles,2MM Gold Plated bangles,2.5MM Gold Plated bangles,3MM Tri Color bangles,3.5MM Micro Gold Plated bangles, 4MM Gold Plated bangles,4.5MM Gold Plated bangles,5MM Gold Plated bangles,5.2MM Gold Plated bangles,5/3MM Odisha Pola bangles,5.5MM Gold Plated bangles,6MM 7 Semanario bangles,6.5MM Gold Plated bangles,7MM 7 Semanario bangles,7.2MM 7 Semanario bangles,7/3MM Gold Plated bangles,7.8MM Micro Gold Plated bangles,8MM 3 Tone Plated bangles,8/3MM Gold Plated bangles,9MM Gold Plated bangles,9.2MM Gold Plated bangles,9/3MM Gold Plated bangles, 10MM Gold Plated bangles,11MM 3 Tone bangles,11/3MM 3 Tone Plated bangles,11/4MM Tri Color bangles,11/5MM 3 Tone Plated bangles,12MM Gold Plated bangles,12/3MM Gold Plated bangles, 15MM Silver & Gold punjabi kada,19MM Gold Plated bangles,20MM Tricolor bangles,20/3MM Tricolor bangles,12MM Silver plated punjabi kada,12MM Gold plated punjabi kada, 3MM pola bangles, 5mm pola bangles, 5/3mm pola bangles,12 mm pola bangles, 5mm gold plated pola bangles, 5mm red pola bangle, 3mm green pola bangles, 5/3mm 2 color pola bangles, 5mm maroon pola bangles, 5mm shakha pola bangles,"
        />
      </Helmet>

      <PolaBanglesHeader
        brand="Home"
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" />}
        fixed
        color="transparent"
        // textcolor="white"
        changeColorOnScroll={{
          height: 300,
          color: "danger",
        }}
      />
      <Parallax
        image={require("assets/img/examples/clark-street-merc.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8} className={classe.textCenter}>
              <h1 className={classe.title}>{catdesc}</h1>
              <h4 style={{ color: "white" }}>
                {catdesc === "BANGLES"
                  ? "Accurate banglkes designs just the way you wanted for special occasions"
                  : catdesc === "EARRINGS"
                  ? "Fashion and style in everything we own."
                  : catdesc === "FINGER RINGS"
                  ? "Be Unique, Be Special, Be Pretty"
                  : catdesc === "WATCH"
                  ? "The intricate designs which you will not find anywhere else."
                  : catdesc === "PUNJABI KADA"
                  ? "Accurate designs just the way you wanted."
                  : ""}
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <PolaSizeMMlist />
      </div>
      <PolaBanglesFooter />
    </div>
  );
}
