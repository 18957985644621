/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import PinDrop from "@material-ui/icons/PinDrop";
import { Link } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailIcon from "@material-ui/icons/Mail";
import { ImMobile } from "react-icons/im";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import city from "assets/img/examples/city.jpg";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PolaBanglesHeader from "./PolaBanglesHeader";
import PolaBanglesHeaderLinks from "./PolaBanglesHeaderLinks";
import Check from "@material-ui/icons/Check";
import CallIcon from "@material-ui/icons/Call";
import ReactGa from "react-ga";
import { localApi } from "utils/headApi.js";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

const useStyles = makeStyles(contactsStyle);
const useStyl = makeStyles(sweetAlertStyle);

const RegularMap = () => {
  const mapRef = React.useRef(null);
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let lat = "44.43353";
    let lng = "26.093928";
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 14,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      styles: [
        {
          featureType: "water",
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: "#0088ff" }],
        },
        {
          featureType: "road",
          elementType: "geometry.fill",
          stylers: [
            { hue: "#ff0000" },
            { saturation: -100 },
            { lightness: 99 },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#808080" }, { lightness: 54 }],
        },
        {
          featureType: "landscape.man_made",
          elementType: "geometry.fill",
          stylers: [{ color: "#ece2d9" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry.fill",
          stylers: [{ color: "#ccdca1" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#767676" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#ffffff" }],
        },
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }, { color: "#b8cb93" }],
        },
        { featureType: "poi.park", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.sports_complex",
          stylers: [{ visibility: "on" }],
        },
        { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
        {
          featureType: "poi.business",
          stylers: [{ visibility: "simplified" }],
        },
      ],
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      animation: google.maps.Animation.DROP,
      title: "Material Kit PRO React!",
    });

    const contentString =
      '<div class="info-window-content"><h2>Material Kit PRO React</h2>' +
      "<p>A premium Kit for React, Material-UI, and React Hooks.</p></div>";

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });
  });
  return (
    <>
      <div
        style={{ height: `100%`, borderRadius: "6px", overflow: "hidden" }}
        ref={mapRef}
      ></div>
    </>
  );
};

//  const Result  =() => {
//    return(
//      <p>Your message has beeen successfully sent.</p>
//    )
//  }
export default function BanglesContactpage({ ...rest }) {
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const classes = useStyles();
  const classSweet = useStyl();

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handleMobile = (e) => {
    setMobile(e.target.value);
    setMobileErr("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailErr("");
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
    setMessageErr("");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter Your Name");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Please Enter Phone Number");
    }
    if (email === "" || !email) {
      errorCount++;
      setEmailErr("Please Enter Email Address");
    }
    if (message === "" || !message) {
      errorCount++;
      setMessageErr("Please Enter Message");
    }
    if (errorCount === 0) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          adminId: "2",
          firstName: firstName,
          mobile: mobile,
          email: email,
          websiteName: "polabangles.com",
          message: message,
        }),
      };

      fetch(localApi + `/contactus/add`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="We are top manufacturers and exporters of brass bangles in Mumbai, well known exporters in Dubai, Saudi Arabia, the USA, Mexico, Iran, Bangladesh, and more. Our bangles are liked by everyone as we provide a wide range of bangles at low prices and of good quality."
        />
        <meta
          name="keywords"
          content="bangles manufacturers in Mumbai, imitation bangles manufacturing in malad west, bangles manufacturers in Goregaon India, bangles exporter in Mumbai India, bangles shop near me, bangles exporters in India, brass bangles manufacturers in Mumbai, brass bangles manufacturers and exporters in kandivali India and abroad, brass bangles online India, imitation bangles manufacturers in India, pola bangles contact number, how to contact polabangles.com, bangles exporter contact number, bangles manufacturers contact number, brass bangles manufacturing company, bangles factory in Karnataka, brass bangles factory in Basvakalyan, bangles manufacturers in sastapur bangala Karnataka, brass bangles manufacturers in Bidar,"
        />
      </Helmet>
      <PolaBanglesHeader
        brand="Home"
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "danger",
        }}
      />
      <div className="cd-section" {...rest}>
        {/* Contact us 1 START */}
        <div
          className={classes.contacts + " " + classes.section}
          style={{ backgroundImage: `url(${city})` }}
        >
          <div className={classes.container}>
            <GridContainer>
              {submitted ? (
                <SweetAlert
                  type={alertType}
                  style={{ display: "block", marginTop: "100px" }}
                  title={alertMsg}
                  onConfirm={() => hideAlert()}
                  confirmBtnCssClass={
                    classSweet.button + " " + classSweet.success
                  }
                />
              ) : null}
              <GridItem xs={12} sm={5} md={5}>
                <h2 className={classes.title}>Find Our Business</h2>
                <h5 className={classes.description}>
                  {/* You need more information? Check what other persons are saying
                  about our product. They are very happy with their purchase. */}
                </h5>
                <InfoArea
                  className={classes.infoArea}
                  title="Find Factory in Karnataka"
                  description={
                    <span>
                      29-989/19 Road 29 Karnataka Auto Nagar, Sastapur
                      Bangala-585327,
                      <br /> Basvakalyan, Dist. Bidar,
                      <br /> Karnataka.
                    </span>
                  }
                  icon={PinDrop}
                  // iconColor="primary"
                />
                <InfoArea
                  className={classes.infoArea}
                  title="Find Office in Mumbai"
                  description={
                    <span>
                      Shop no 20, Ashmita Jyoti CHS D10/11 Bldg.
                      <br /> Charkoap Naka, Marve Road, Malad West
                      <br />
                      Mumbai-400095 ,Maharashtra.
                    </span>
                  }
                  icon={PinDrop}
                />
                <Link
                  to="javascript:void(0)"
                  onClick={() =>
                    (window.location = "mailto:info@polabangles.com")
                  }
                >
                  <InfoArea
                    className={classes.infoArea}
                    title="info@polabangles.com"
                    // description={
                    //   <span>
                    //  info@polabangles.com
                    //   </span>
                    // }
                    icon={MailIcon}
                  />
                </Link>
                <a
                  href={`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                  // href="https://api.whatsapp.com/send?phone=919082645085"
                  // href={`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                  target="_blank"
                >
                  {/* <WhatsAppIcon style={{ color:"green" , fontSize:"30px"}}  /> */}
                  {/* Click on the icon to get connected with us on WhatsApp   */}
                  <InfoArea
                    className={classes.infoArea}
                    title="Click on the icon to get connected with us on WhatsApp"
                    // icon={SiWhatsapp}
                    // description={
                    //   <span>
                    //   02240128886
                    //   </span>
                    // }
                    icon={WhatsAppIcon}
                    iconColor="success"
                  />
                </a>
                <a href="tel:+91-2247501787">
                  <InfoArea
                    className={classes.infoArea}
                    title="2247501787"
                    // icon={SiWhatsapp}
                    // description={
                    //   <span>
                    //   02240128886
                    //   </span>
                    // }
                    icon={CallIcon}
                    iconColor="success"
                  />
                </a>

                <a href="tel:+91-9967457786">
                  <InfoArea
                    className={classes.infoArea}
                    title="+919967457786"
                    icon={ImMobile}
                  />
                </a>
              </GridItem>
              <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
                <Card className={classes.card1}>
                  {/* <> */}
                  <form>
                    {/* onSubmit={(e) => handleSubmit(e)} */}

                    <CardHeader
                      contact
                      color="danger"
                      className={classes.textCenter}
                    >
                      <h4 className={classes.cardTitle}>Contact Us</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Your Name"
                        id="fristName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "fristName",
                          value: firstName,
                          onChange: (e) => handleFirstName(e),
                        }}
                        required={true}
                      />
                      <span style={{ color: "red" }}>{firstNameErr}</span>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Email address"
                            id="email"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "email",
                              value: email,
                              onChange: (e) => handleEmail(e),
                            }}
                            required={true}
                          />
                          <span style={{ color: "red" }}>{emailErr}</span>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <CustomInput
                            labelText="Phone Number"
                            id="mobile"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "mobile",
                              value: mobile,
                              onChange: (e) => handleMobile(e),
                            }}
                            required={true}
                          />
                          <span style={{ color: "red" }}>{mobileErr}</span>
                        </GridItem>
                      </GridContainer>

                      <CustomInput
                        labelText="Your message"
                        id="message"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          multiline: true,
                          rows: 6,
                          name: "message",
                          value: message,
                          onChange: (e) => handleMessage(e),
                        }}
                        required={true}
                      />
                      <span style={{ color: "red" }}>{messageErr}</span>
                    </CardBody>
                    <CardFooter className={classes.justifyContentBetween}>
                      <Button
                        color="danger"
                        className={classes.pullLeft}
                        onClick={onSubmit}
                      >
                        Send Message
                      </Button>
                      {/* <Button  onClick={ClickHandler}>Click here</Button> */}
                    </CardFooter>
                    {/* <div className="row">Result : {result ? <Result/> : null }</div> */}

                    {/* <CardFooter className={classes.justifyContentBetween}>
                   
                    </CardFooter> */}
                  </form>
                  {/* </> */}
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}></div>
          {/* <Footer
        content={
          <div>          
            <div className={classes.right}>
            Copyright &copy; {1900 + new Date().getYear()}, Pola Bangles All Rights Reserved.           
            </div>
          </div>
        }
      /> */}
        </div>
      </div>
    </div>
  );
}
