/*eslint-disable*/
import React, { useState, useEffect, useStyles } from "react";
import ReactImageProcess from "react-image-process";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Favorite from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import gucci from "assets/img/examples/gucci.jpg";
import QWE from "assets/img/examples/QWE.jpg";
import ASD from "assets/img/examples/ASD.jpg";
import ZXC from "assets/img/examples/ZXC.jpg";
import MNP from "assets/img/examples/MNP.jpg";
import JKL from "assets/img/examples/JKL.jpg";
import logo from "assets/img/examples/apple-pola-bangles-icon.png";
import Cached from "@material-ui/icons/Cached";
import Subject from "@material-ui/icons/Subject";
import Check from "@material-ui/icons/Check";
import { headApi } from "utils/headApi";
import { adminId } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
// import logo from "assets/img/examples/apple-insha-bangles-icon.png";

const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PolaBanglesOffers() {
  const [loginModal, setLoginModal] = React.useState(false);
  const [page, setpage] = React.useState(0);
  const [products, setProducts] = useState([]);
  const [isloading, setLoading] = useState(false);

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const classPop = useStylPop();

  // const userSession = JSON.parse(sessionStorage.getItem("sessionData"));
  // const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const activeSession = sessionStorage.getItem("sessionData");

  function sayHi() {
    console.log("Hello!");
  }

  const fetchData = async () => {
    setpage(page + 1);
    axios
      .get(
        localApi +
        `/product/list/byLimitActive?adminId=2&active=1&page=${page}&size=4`
      )
      .then((res) => setProducts([...products, ...res.data]));
  };

  useEffect(() => {
    fetchData();
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
    setLoading(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  // async function getProducts() {
  //   setLoading(true);
  //   const api = headApi + `/products?userid=${adminId}&active=1`;
  //   const result = await fetch(api);
  //   const getResult = await result.json();
  //   setProducts(getResult.ProductsData);
  // }

  // useEffect(() => {
  //   getProducts();
  //   setLoading(true);
  //   setTimeout(hideAlertPop, 2000);
  //   setLoginModal(true)
  // }, []);

  // const hideAlertPop = () => {
  //   setLoginModal(false)
  // };

  const secure = "../../secure/crop_img/0608201902.jpg";
  const design_img = "../../design_img/0708201901.jpg";

  const LoadMore = () => {
    if (isloading) {
      return;
    }
  };

  var sum = 0;
  var p = 0;
  var m;
  var n;

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {/* <h2>News in fashion</h2> */}

        <GridContainer>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${QWE})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Apps */}
                </h6>
                <a href="/bangles-product?id=277&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>
                    RED & WHITE POLA BANGLES
                  </h3>
                </a>
                <p className={classes.description}>
                  Each of our pieces made with good quality raw material and
                  polished with fine gold plating that brings out the hidden
                  beauty around you.
                </p>
                <Button
                  href="/bangles-product?id=277&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=277&size=5MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${ASD})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* FASHION NEWS */}
                </h6>
                <a href="/bangles-product?id=386&catdesc=Bangles&cat1desc=8MM&Plating=3tone">
                  <h3 className={classes.cardTitle}>3 TONE BANGLES</h3>
                </a>
                <p className={classes.description}>
                  Come in and find out why we have the biggest collection of
                  Bangles, You won’t find a better deal anywhere else.
                </p>
                <Button
                  href="/bangles-product?id=386&catdesc=Bangles&cat1desc=8MM&Plating=3tone"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=386&size=5MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${ZXC})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Apps */}
                </h6>
                <a href="/bangles-product?id=293&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>KANYASHREE KADA</h3>
                </a>
                <p className={classes.description}>
                  All our bangles are easy on your budget, when you need a
                  sparkle of brilliance or a touch of shine, we’re here to help.
                </p>
                <Button
                  href="/bangles-product?id=293&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=293&size=6MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${MNP})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Tutorials */}
                </h6>
                <a href="/bangles-product?id=398&catdesc=Bangles&cat1desc=12MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>ODISHA POLA BANGLES</h3>
                </a>
                <p className={classes.description}>
                  For women and Girls looking for something special to
                  commemorate a cherished occasion, These are sure to fit in
                  perfectly with your style
                </p>
                <Button
                  href="/bangles-product?id=398&catdesc=Bangles&cat1desc=12MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=398&size=12MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${JKL})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Style */}
                </h6>
                <a href="/bangles-product?id=351&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>
                    MAHARASHTRIAN GREEN POLA BANGLES
                  </h3>
                </a>
                <p className={classes.description}>
                  Everyday styled bangles, fancy and party wear bangles in your
                  budget. You can choose the type of bangles design you would
                  like to wear as it comes in different styles and fashion.
                </p>
                <Button
                  href="/bangles-product?id=351&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=351&size=5MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Dialog
          style={{ marginTop: "10%" }}
          classes={{
            root: classPop.modalRoot,
            paper: classPop.modal + " " + classPop.modalLogin,
          }}
          open={loginModal}
          TransitionComponent={Transition}
          keepMounted
          // onClose={() => setLoginModal(false)}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          <DialogTitle
            id="login-modal-slide-title"
            disableTypography
            className={classPop.modalHeader}
          >
            <h5>Loading...</h5>
            <DialogContent
              id="login-modal-slide-description"
              className={classPop.modalBody}
            >
              <GridContainer justify="center">
                <Spinner
                  onChange={() => hideAlertPop()}
                  style={{ color: "#f44336" }}
                />
              </GridContainer>
              <br />
            </DialogContent>
          </DialogTitle>
        </Dialog>

        <h2>Popular All items Today</h2>

        <InfiniteScroll
          dataLength={products.length}
          next={fetchData}
          hasMore={true}
        >
          <div className={classes.container}>
            <GridContainer>
              {products &&
                products.map((prod) => (
                  <GridItem md={3} sm={6} key={prod.id}>
                    <Card product plain>
                      <CardHeader image plain>
                        {/* <CornerRibbon
                          position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                          backgroundColor="#f44336" // OPTIONAL, default as "#2c7"
                          containerStyle={{}} // OPTIONAL, style of the ribbon
                          style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                          className="" // OPTIONAL, css class of ribbon
                        > {prod.total > 0 ? "In Stock" : "Make To Order"}
                        </CornerRibbon> */}
                        {/* <a href="#pablo"> */}
                        {/* <img src={gucci} alt="..." /> */}
                        <img
                          src={
                            prod.productImage[0].cropImageUrl +
                            "?id=" +
                            prod.productImage[0].imgId
                          }
                          alt="..."
                        />
                        <p
                          style={{
                            position: "absolute",
                            top: "65%",
                            left: "50%",
                            width: "60px",
                            height: "60px",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <img src={logo} alt="..." />
                        </p>
                        {/* </a> */}
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${gucci})`,
                            opacity: 1,
                          }}
                        />
                      </CardHeader>
                      <CardBody className={classes.textCenter} plain>
                        <h6
                          className={classNames(
                            classes.cardCategory,
                            classes.textRose
                          )}
                        >
                          {prod.productCategory.title +
                            " " +
                            prod.productParent.title}{" "}
                          <br /> {prod.itemCodeNumber}-{prod.id}
                        </h6>
                        <h4 className={classes.cardTitle}>
                          {prod.prodDescription}
                        </h4>
                        <div className={classes.cardDescription}>
                          {(prod.readyGram * prod.productSets.pcs).toFixed(4)}{" "}
                          Avg Gram Of {prod.productSets.setDesc} {"and "}
                          {prod.productBox.boxDesc}
                          {","} {prod.cartDescription}
                          {"."}
                        </div>
                      </CardBody>
                      <CardFooter className={classes.justifyContentBetween}>
                        <div className={classes.price}>
                          {prod.gstCash === true ? (
                            <>
                              <h5>
                                <span style={{ color: "red" }}>
                                  Price ₹ {(prod.taxInclude / prod.productBox.box).toFixed(2) + " Inc."}
                                </span>
                              </h5>
                            </>
                          ) : (
                            <>
                              <h5>
                                <span style={{ color: "black" }}>
                                  Price ₹ {(prod.cashPrice / prod.productBox.box).toFixed(2)}
                                </span>
                              </h5>
                            </>
                          )}
                          {/* {activeSession ? "" : prod.taxCalculation.profitactive === 1 ? <h5>Price <span style={{ color: "red" }}>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> : <h5>Price <span>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-*</span></h5>}

                                                {!activeSession ? <h6><a href="/bangles-login">Login</a> to see prices and exciting offers.
                                                </h6> : prod.taxCalculation.profitactive === 1 ? <h6>Price <span style={{ textDecoration: "line-through", color: "red" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span>{" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> :
                                                    <h6>Price <span style={{ textDecoration: "line-through" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span> {" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6>
                                                } */}

                          {/* {!activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              prod.taxCalculation.cash_gst === 1 ? (
                                <>
                                  <h5>
                                    <span style={{ color: "red" }}>
                                      Price ₹{" "}
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /-
                                    </span>
                                  </h5>{" "}
                                  <h6>
                                    <a href="/bangles-login">Login</a> to see
                                    prices and exciting offers.
                                  </h6>{" "}
                                </>
                              ) : (
                                <h5>
                                  Price{" "}
                                  <span>
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </span>
                                </h5>
                              )
                            ) : prod.taxCalculation.cash_gst === 1 ? (
                              <>
                                <h5>
                                  <span style={{ color: "#9c27b0" }}>
                                    Price ₹{" "}
                                    {(
                                      prod.taxCalculation.taxincl / prod.box.box
                                    ).toFixed(2)}
                                    /-
                                  </span>
                                </h5>{" "}
                                <h6>
                                  <a href="/bangles-login">Login</a> to see
                                  prices and exciting offers.
                                </h6>
                              </>
                            ) : (
                              <h5>
                                Price{" "}
                                <span>
                                  ₹{" "}
                                  {(
                                    prod.taxCalculation.cash / prod.box.box
                                  ).toFixed(2)}
                                  /-*
                                </span>
                              </h5>
                            )
                          ) : (
                            ""
                          )}

                          {activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "red",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            ) : (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#9c27b0",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            )
                          ) : (
                            ""
                          )} */}
                        </div>
                        <div className={classes.stats}>
                          <Tooltip
                            id="tooltip-top"
                            title="Save to Wishlist"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button justIcon color="rose" simple>
                              <Favorite />
                            </Button>
                          </Tooltip>
                        </div>
                      </CardFooter>
                      <div style={{ textAlign: "center", marginBottom: "7px" }}>
                        <Button
                          color="danger"
                          href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                        // &Plating=${prod.plating.description.slice(8)}
                        >
                          Buy Now
                        </Button>
                      </div>
                    </Card>
                  </GridItem>
                ))}
            </GridContainer>
          </div>
        </InfiniteScroll>
        {products ? (
          <h4>
            <b>Bangles with the best standard</b> :- We are one of the top
            manufacturers and retailers in India, having hundreds of satisfied
            and happy clients from India and around the globe. We manufacture
            bangles of different models, sizes, and colors like Micro gold
            plated Rose gold, silver plated, 2 tone & 3 tone 7Seminario bangles
            at very affordable low prices.
          </h4>
        ) : (
          ""
        )}
        <GridItem
          md={3}
          sm={3}
          className={classNames(classes.mlAuto, classes.mrAuto)}
        >
          <Button round color="danger" onClick={LoadMore}>
            Load more...
          </Button>
        </GridItem>
      </div>
    </div>

    // </div>
  );
}
