/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 style={{ textAlign: "center" }} className={classes.description}>
            We are India's one-stop shop for all your bangles needs, working
            especially on Pola Bangles. We are well known and popular in making
            Odisha pola bangles, Maharashtra pola bangles, Bengal pola bangles,
            Marwadi pola bangles, and Guajarati traditional pola bangles. We are
            one of the largest Manufacturers of Bangles for all festivals and
            occasions. We ensure that we create value for our customers in this
            ever-changing market. We have a no-compromise policy when it comes
            to quality. Our stringent adherence to the quality of the product,
            as well as service (including after-sales service), has further
            ensured that POLABANGLES.COM provides the best products to the
            customers.
          </h5>
          <h5 className={classes.description}>
            The Company was established in 2008 and was certified by ISO
            9001:2015 (Quality Management System) in the year 2017. We focus on
            offering a tasteful and wide range of designer bangles at reasonable
            rates with the lowest making charges. In a very short span of time,
            polabangles.com has built a large family of loyal clients in India
            (especially in Kolkata, Odisha, Maharashtra, Gujarat, and Rajasthan.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
