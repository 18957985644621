/*eslint-disable*/
import React from "react";
import '../MyCompo/style.css';
import { makeStyles } from "@material-ui/core/styles";
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import office2 from "assets/img/examples/office2.jpg";
import blog8 from "assets/img/examples/blog8.jpg";
import GoldPlated8mmBangles from "assets/img/examples/GoldPlated8mmBangles.jpg";
import IconButton from '@material-ui/core/IconButton';
import ReactGa from 'react-ga'
import sectionPillsStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionPillsStyle.js";

const useStyles = makeStyles(sectionPillsStyle);

export default function SectionLandingpage() {
  const classes = useStyles();
  const noPointer = {cursor: 'default'};
  // const fontSize = {fontSize:'30px'}

  function sayHi() {
    console.log("Hello!");
  }


  const ClickHandler = () => {
    ReactGa.event({
      category:'Gold plated',
      action: 'Click on the landing page',
      // country: 'India',
      
    })
    // alert('thank you for visiting the website contact us')
  // console.log(alert);
  }
  

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} className={classes.textCenter}>
          <div className={classes.tabSpace} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + office2 + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}></h6>
              <a href="#">
                <h3 className={classes.cardTitle}>
                Ready for every occasion
                </h3>
              </a>
              <p className={classes.category}>
              In India bangles are parts of every occasion weather its Diwali,
              Durga Ashtmi, Eid or A marriage ceremony. For traditional ceremonies
              only circular glass or metal bangles are preferred.
              </p>
              <Button
                 round href="#" color="danger">
                <FormatAlignLeft   className={classes.icons}  onClick={ClickHandler}/> Buy Now          
              </Button>
              <a href={`https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=346&size=5MM&cat=POLA&app_absent=0`} target="_blank"><IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"37px", width:"37px" }} onPress={sayHi} >
                   <i className="fab fa-whatsapp" />
                  </IconButton></a>
            </CardBody>           
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + blog8 + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}></h6>
              <a href="#pablo">
                <h3 className={classes.cardTitle}>
                Quality is what we focus on
                </h3>
              </a>
              <p className={classes.category}>
              We offer best quality bangles to our customers, with 6
              months colour guarantee. Also we use finest quality
              raw material to make the bangles look charming and elegant.
              </p>
              <Button             
                 round href="/#" color="danger">
                <FormatAlignLeft className={classes.icons}  onClick={ClickHandler}/> Buy Now
              </Button>
              
              <a href={`https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=346&size=5MM&cat=POLA&app_absent=0`} target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"37px", width:"37px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
              </IconButton></a>
           </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card
            raised
            background
            style={{ backgroundImage: "url(" + GoldPlated8mmBangles + ")" }}
          >
            <CardBody background>
              <h6 className={classes.category}></h6>
              <a href="#pablo">
                <h3 className={classes.cardTitle}>
                Customised order
                </h3>
              </a>
              <p className={classes.category}>
              Customise the order as per your requirements. we make bangles on "make to" order.
               You can get bangles of any size, design and colour
              </p>
              <Button 
                  round href="/bangles-product?id=346&catdesc=Bangles&cat1desc=8MM&Plating=Gold%20Plated"  color="danger">
                <FormatAlignLeft className={classes.icons} onClick={ClickHandler}/> Buy Now
              </Button>

              <a href={`https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=346&size=5MM&cat=POLA&app_absent=0`} target="_blank"><IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"37px", width:"37px" }}   onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
              </IconButton></a>
             
              {/* <Tooltip
                id="tooltip-pocket"
                title="Save to Pocket"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button color="white" simple justIcon>
                  <i className="fab fa-get-pocket" />
                </Button>
              </Tooltip> */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
