/*eslint-disable*/
import React, { useState, useEffect, useStyles } from "react";
import ReactImageProcess from "react-image-process";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Favorite from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";

import gucci from "assets/img/examples/gucci.jpg";
import QWE from "assets/img/examples/QWE.jpg";
import ASD from "assets/img/examples/ASD.jpg";
import ZXC from "assets/img/examples/ZXC.jpg";
import MNP from "assets/img/examples/MNP.jpg";
import JKL from "assets/img/examples/JKL.jpg";
import logo from "assets/img/examples/apple-pola-bangles-icon.png";
import Cached from "@material-ui/icons/Cached";
import Subject from "@material-ui/icons/Subject";
import Check from "@material-ui/icons/Check";
import { headApi } from "utils/headApi";
import { adminId } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import { TiArrowForward } from "react-icons/ti";
// import logo from "assets/img/examples/apple-insha-bangles-icon.png";

const useStylPop = makeStyles(popOver);
const useStyleSize = makeStyles(productStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PolaBanglesOffers() {
  const [loginModal, setLoginModal] = React.useState(false);
  const useStyles = makeStyles(styles);

  const classes = useStyles();
  const classSize = useStyleSize();
  const classPop = useStylPop();
  const history = useHistory();

  // const userSession = JSON.parse(sessionStorage.getItem("sessionData"));
  // const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const [products, setProducts] = useState([]);

  const [isloading, setLoading] = useState(false);

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  function sayHi() {
    console.log("Hello!");
  }

  async function getProducts() {
    setLoading(true);
    const api =
      localApi + `/product/list/byAdminId/${userObj ? userObj.admin.id : "2"}`;
    const result = await fetch(api);
    const getResult = await result.json();
    const pStock = getResult.filter((f) => f.stockSize.length === 1);
    setProducts(pStock);
  }

  useEffect(() => {
    getProducts();
    setLoading(true);
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  const secure = "../../secure/crop_img/0608201902.jpg";
  const design_img = "../../design_img/0708201901.jpg";

  const LoadMore = () => {
    if (isloading) {
      return;
    }
  };

  var sum = 0;
  var p = 0;
  var m;
  var n;

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {/* <h2>News in fashion</h2> */}

        <GridContainer>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${QWE})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Apps */}
                </h6>
                <a href="/bangles-product?id=277&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>
                    RED & WHITE POLA BANGLES
                  </h3>
                </a>
                <p className={classes.description}>
                  Each of our pieces made with good quality raw material and
                  polished with fine gold plating that brings out the hidden
                  beauty around you.
                </p>
                <Button
                  href="/bangles-product?id=277&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=277&size=5MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${ASD})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* FASHION NEWS */}
                </h6>
                <a href="/bangles-product?id=386&catdesc=Bangles&cat1desc=8MM&Plating=3tone">
                  <h3 className={classes.cardTitle}>3 TONE BANGLES</h3>
                </a>
                <p className={classes.description}>
                  Come in and find out why we have the biggest collection of
                  Bangles, You won’t find a better deal anywhere else.
                </p>
                <Button
                  href="/bangles-product?id=386&catdesc=Bangles&cat1desc=8MM&Plating=3tone"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=386&size=5MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${ZXC})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Apps */}
                </h6>
                <a href="/bangles-product?id=293&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>KANYASHREE KADA</h3>
                </a>
                <p className={classes.description}>
                  All our bangles are easy on your budget, when you need a
                  sparkle of brilliance or a touch of shine, we’re here to help.
                </p>
                <Button
                  href="/bangles-product?id=293&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=293&size=6MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${MNP})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Tutorials */}
                </h6>
                <a href="/bangles-product?id=398&catdesc=Bangles&cat1desc=12MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>ODISHA POLA BANGLES</h3>
                </a>
                <p className={classes.description}>
                  For women and Girls looking for something special to
                  commemorate a cherished occasion, These are sure to fit in
                  perfectly with your style
                </p>
                <Button
                  href="/bangles-product?id=398&catdesc=Bangles&cat1desc=12MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=398&size=12MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${JKL})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Style */}
                </h6>
                <a href="/bangles-product?id=351&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>
                    MAHARASHTRIAN GREEN POLA BANGLES
                  </h3>
                </a>
                <p className={classes.description}>
                  Everyday styled bangles, fancy and party wear bangles in your
                  budget. You can choose the type of bangles design you would
                  like to wear as it comes in different styles and fashion.
                </p>
                <Button
                  href="/bangles-product?id=351&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated"
                  round
                  color="danger"
                >
                  <Subject /> Read
                </Button>
                <a
                  href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=351&size=5MM&cat=POLA&app_absent=0"
                  target="_blank"
                >
                  {" "}
                  <IconButton
                    style={{
                      color: "white",
                      fontSize: "40px",
                      backgroundColor: "#42d429",
                      height: "35px",
                      width: "35px",
                    }}
                    onPress={sayHi}
                  >
                    <i className="fab fa-whatsapp" />
                  </IconButton>{" "}
                </a>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Dialog
          style={{ marginTop: "10%" }}
          classes={{
            root: classPop.modalRoot,
            paper: classPop.modal + " " + classPop.modalLogin,
          }}
          open={loginModal}
          TransitionComponent={Transition}
          keepMounted
          // onClose={() => setLoginModal(false)}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          <DialogTitle
            id="login-modal-slide-title"
            disableTypography
            className={classPop.modalHeader}
          >
            <h5>Loading...</h5>
            <DialogContent
              id="login-modal-slide-description"
              className={classPop.modalBody}
            >
              <GridContainer justify="center">
                <Spinner
                  onChange={() => hideAlertPop()}
                  style={{ color: "#f44336" }}
                />
              </GridContainer>
              <br />
            </DialogContent>
          </DialogTitle>
        </Dialog>

        <h2>Popular All items Today</h2>
        <GridContainer>
          {products &&
            products.map((prod) => (
              <GridItem md={3} sm={6} key={prod.id}>
                <Card product plain>
                  <CardHeader image plain>
                    {/* <CornerRibbon
                                            position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                                            fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                                            backgroundColor="#f44336" // OPTIONAL, default as "#2c7"
                                            containerStyle={{}} // OPTIONAL, style of the ribbon
                                            style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                                            className="" // OPTIONAL, css class of ribbon
                                        > {prod.total > 0 ? "In Stock" : "Make To Order"}
                                        </CornerRibbon> */}
                    {/* <a href="#pablo"> */}
                    {/* <img src={gucci} alt="..." /> */}
                    <p
                            style={{
                              position: "absolute",
                              left: "90%",
                              top: "90%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <Tooltip
                              id="tooltip-tops"
                              title="Share to WhatsApp"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <a
                                href={`https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id=${prod.id}%20${prod.productCategory.title}%20${prod.productParent.title}&app_absent=0`}
                              >
                                {prod.productImage[0].cropImageUrl +
                                "?id=" +
                                prod.productImage[0].imgId ? (
                                  <TiArrowForward size={50} color="#00acc1" />
                                ) : (
                                  ""
                                )}
                                {/* {imgApi + "" + prod.design1.image1 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design2.image2 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design3.image3 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design4.image4 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design5.image5 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : imgApi + "" + prod.design6.image6 !==
                                                                    imgApi + "" + "/crop_img/null" ? (
                                                                    <TiArrowForward size={50} color="#00acc1" />
                                                                ) : (
                                                                    ""
                                                                )} */}
                              </a>
                            </Tooltip>
                          </p>
                    <img
                      src={
                        prod.productImage[0].cropImageUrl +
                        "?id=" +
                        prod.productImage[0].imgId
                      }
                      alt="..."
                    />
                    <p
                      style={{
                        position: "absolute",
                        top: "65%",
                        left: "50%",
                        width: "60px",
                        height: "60px",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <img src={logo} alt="..." />
                    </p>
                    {/* </a> */}
                    <div
                      className={classes.coloredShadow}
                      style={{ backgroundImage: `url(${gucci})`, opacity: 1 }}
                    />
                  </CardHeader>
                  <CardBody className={classes.textCenter} plain>
                    <h5 className={classes.cardTitle}>
                      {prod.productCategory.title} <br />{" "}
                      {prod.productParent.title} {prod.itemcode}-{prod.id}
                    </h5>
                    <p className={classes.cardDescription}>
                      {(prod.readyGram * prod.productSets.pcs).toFixed(4)} Avg
                      Gram Of {prod.productSets.setDesc} {"and "}
                      {prod.productBox.boxDesc}
                      {","} {prod.cartDescription}
                      {"."}
                      <br />
                    </p>
                    <GridContainer className={classSize.sizePadding}>
                      {prod.stockSize[0].s1x8 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x8 == null
                            ? ""
                            : prod.stockSize[0].s1x8 == 0
                            ? ""
                            : "1x8"}
                          <br />
                          {prod.stockSize[0].s1x8 == null
                            ? ""
                            : prod.stockSize[0].s1x8 == 0
                            ? ""
                            : prod.stockSize[0].s1x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s1x10 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x10 == null
                            ? ""
                            : prod.stockSize[0].s1x10 == 0
                            ? ""
                            : "1x10"}
                          <br />
                          {prod.stockSize[0].s1x10 == null
                            ? ""
                            : prod.stockSize[0].s1x10 == 0
                            ? ""
                            : prod.stockSize[0].s1x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s1x12 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x12 == null
                            ? ""
                            : prod.stockSize[0].s1x12 == 0
                            ? ""
                            : "1x12"}
                          <br />
                          {prod.stockSize[0].s1x12 == null
                            ? ""
                            : prod.stockSize[0].s1x12 == 0
                            ? ""
                            : prod.stockSize[0].s1x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s1x14 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x14 == null
                            ? ""
                            : prod.stockSize[0].s1x14 == 0
                            ? ""
                            : "1x14"}
                          <br />
                          {prod.stockSize[0].s1x14 == null
                            ? ""
                            : prod.stockSize[0].s1x14 == 0
                            ? ""
                            : prod.stockSize[0].s1x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x0 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x0 == null
                            ? ""
                            : prod.stockSize[0].s2x0 == 0
                            ? ""
                            : "2x0"}
                          <br />
                          {prod.stockSize[0].s2x0 == null
                            ? ""
                            : prod.stockSize[0].s2x0 == 0
                            ? ""
                            : prod.stockSize[0].s2x0}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x2 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x2 == null
                            ? ""
                            : prod.stockSize[0].s2x2 == 0
                            ? ""
                            : "2x2"}
                          <br />
                          {prod.stockSize[0].s2x2 == null
                            ? ""
                            : prod.stockSize[0].s2x2 == 0
                            ? ""
                            : prod.stockSize[0].s2x2}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x4 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x4 == null
                            ? ""
                            : prod.stockSize[0].s2x4 == 0
                            ? ""
                            : "2x4"}
                          <br />
                          {prod.stockSize[0].s2x4 == null
                            ? ""
                            : prod.stockSize[0].s2x4 == 0
                            ? ""
                            : prod.stockSize[0].s2x4}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x6 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x6 == null
                            ? ""
                            : prod.stockSize[0].s2x6 == 0
                            ? ""
                            : "2x6"}
                          <br />
                          {prod.stockSize[0].s2x6 == null
                            ? ""
                            : prod.stockSize[0].s2x6 == 0
                            ? ""
                            : prod.stockSize[0].s2x6}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x8 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x8 == null
                            ? ""
                            : prod.stockSize[0].s2x8 == 0
                            ? ""
                            : "2x8"}
                          <br />
                          {prod.stockSize[0].s2x8 == null
                            ? ""
                            : prod.stockSize[0].s2x8 == 0
                            ? ""
                            : prod.stockSize[0].s2x8}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x10 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x10 == null
                            ? ""
                            : prod.stockSize[0].s2x10 == 0
                            ? ""
                            : "2x10"}
                          <br />
                          {prod.stockSize[0].s2x10 == null
                            ? ""
                            : prod.stockSize[0].s2x10 == 0
                            ? ""
                            : prod.stockSize[0].s2x10}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x12 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x12 == null
                            ? ""
                            : prod.stockSize[0].s2x12 == 0
                            ? ""
                            : "2x12"}
                          <br />
                          {prod.stockSize[0].s2x12 == null
                            ? ""
                            : prod.stockSize[0].s2x12 == 0
                            ? ""
                            : prod.stockSize[0].s2x12}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x14 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x14 == null
                            ? ""
                            : prod.stockSize[0].s2x14 == 0
                            ? ""
                            : "2x14"}
                          <br />
                          {prod.stockSize[0].s2x14 == null
                            ? ""
                            : prod.stockSize[0].s2x14 == 0
                            ? ""
                            : prod.stockSize[0].s2x14}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s3x0 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s3x0 == null
                            ? ""
                            : prod.stockSize[0].s3x0 == 0
                            ? ""
                            : "3x0"}
                          <br />
                          {prod.stockSize[0].s3x0 == null
                            ? ""
                            : prod.stockSize[0].s3x0 == 0
                            ? ""
                            : prod.stockSize[0].s3x0}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                    <GridContainer className={classSize.sizePadding}>
                      {prod.stockSize[0].s1x9 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x9 == null
                            ? ""
                            : prod.stockSize[0].s1x9 == 0
                            ? ""
                            : "1x9"}
                          <br />
                          {prod.stockSize[0].s1x9 == null
                            ? ""
                            : prod.stockSize[0].s1x9 == 0
                            ? ""
                            : prod.stockSize[0].s1x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s1x11 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x11 == null
                            ? ""
                            : prod.stockSize[0].s1x11 == 0
                            ? ""
                            : "1x11"}
                          <br />
                          {prod.stockSize[0].s1x11 == null
                            ? ""
                            : prod.stockSize[0].s1x11 == 0
                            ? ""
                            : prod.stockSize[0].s1x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s1x13 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x13 == null
                            ? ""
                            : prod.stockSize[0].s1x13 == 0
                            ? ""
                            : "1x13"}
                          <br />
                          {prod.stockSize[0].s1x13 == null
                            ? ""
                            : prod.stockSize[0].s1x13 == 0
                            ? ""
                            : prod.stockSize[0].s1x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s1x15 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s1x15 == null
                            ? ""
                            : prod.stockSize[0].s1x15 == 0
                            ? ""
                            : "1x15"}
                          <br />
                          {prod.stockSize[0].s1x15 == null
                            ? ""
                            : prod.stockSize[0].s1x15 == 0
                            ? ""
                            : prod.stockSize[0].s1x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x1 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x1 == null
                            ? ""
                            : prod.stockSize[0].s2x1 == 0
                            ? ""
                            : "2x1"}
                          <br />
                          {prod.stockSize[0].s2x1 == null
                            ? ""
                            : prod.stockSize[0].s2x1 == 0
                            ? ""
                            : prod.stockSize[0].s2x1}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x3 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x3 == null
                            ? ""
                            : prod.stockSize[0].s2x3 == 0
                            ? ""
                            : "2x3"}
                          <br />
                          {prod.stockSize[0].s2x3 == null
                            ? ""
                            : prod.stockSize[0].s2x3 == 0
                            ? ""
                            : prod.stockSize[0].s2x3}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x5 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x5 == null
                            ? ""
                            : prod.stockSize[0].s2x5 == 0
                            ? ""
                            : "2x5"}
                          <br />
                          {prod.stockSize[0].s2x5 == null
                            ? ""
                            : prod.stockSize[0].s2x5 == 0
                            ? ""
                            : prod.stockSize[0].s2x5}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x7 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x7 == null
                            ? ""
                            : prod.stockSize[0].s2x7 == 0
                            ? ""
                            : "2x7"}
                          <br />
                          {prod.stockSize[0].s2x7 == null
                            ? ""
                            : prod.stockSize[0].s2x7 == 0
                            ? ""
                            : prod.stockSize[0].s2x7}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x9 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x9 == null
                            ? ""
                            : prod.stockSize[0].s2x9 == 0
                            ? ""
                            : "2x9"}
                          <br />
                          {prod.stockSize[0].s2x9 == null
                            ? ""
                            : prod.stockSize[0].s2x9 == 0
                            ? ""
                            : prod.stockSize[0].s2x9}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x11 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x11 == null
                            ? ""
                            : prod.stockSize[0].s2x11 == 0
                            ? ""
                            : "2x11"}
                          <br />
                          {prod.stockSize[0].s2x11 == null
                            ? ""
                            : prod.stockSize[0].s2x11 == 0
                            ? ""
                            : prod.stockSize[0].s2x11}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x13 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x13 == null
                            ? ""
                            : prod.stockSize[0].s2x13 == 0
                            ? ""
                            : "2x13"}
                          <br />
                          {prod.stockSize[0].s2x13 == null
                            ? ""
                            : prod.stockSize[0].s2x13 == 0
                            ? ""
                            : prod.stockSize[0].s2x13}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s2x15 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s2x15 == null
                            ? ""
                            : prod.stockSize[0].s2x15 == 0
                            ? ""
                            : "2x15"}
                          <br />
                          {prod.stockSize[0].s2x15 == null
                            ? ""
                            : prod.stockSize[0].s2x15 == 0
                            ? ""
                            : prod.stockSize[0].s2x15}
                        </span>
                      ) : (
                        ""
                      )}
                      {prod.stockSize[0].s3x1 != 0 ? (
                        <span
                          className={
                            classSize.textCenter +
                            " " +
                            classSize.sizeSpan +
                            " " +
                            classSize.listDescriptionMM
                          }
                        >
                          {prod.stockSize[0].s3x1 == null
                            ? ""
                            : prod.stockSize[0].s3x1 == 0
                            ? ""
                            : "3x1"}
                          <br />
                          {prod.stockSize[0].s3x1 == null
                            ? ""
                            : prod.stockSize[0].s3x1 == 0
                            ? ""
                            : prod.stockSize[0].s3x1}
                        </span>
                      ) : (
                        ""
                      )}
                    </GridContainer>
                  </CardBody>
                  {/* <CardFooter className={classes.justifyContentBetween}>
                    <div className={classes.price}>
                      <Button
                        color="danger"
                        href={`/bangles-product?id=${prod.id}&catdesc=${
                          prod.cat.description
                        }&cat1desc=${
                          prod.banglessizemm.description
                        }&Plating=${prod.plating.description.slice(8)}`}
                      >
                        Buy Now
                      </Button>
                    </div>
                    <div>
                      <a
                        href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp}&text=https://polabangles.com/bangles-product?id=${prod.id}&app_absent=0`}
                        target="_blank"
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IconButton
                          style={{
                            color: "white",
                            fontSize: "40px",
                            backgroundColor: "#42d429",
                            height: "37px",
                            width: "37px",
                          }}
                        >
                          <i className="fab fa-whatsapp" />
                        </IconButton>
                      </a>
                      <Button color="success" round justIcon href="https://api.whatsapp.com/send/?phone=919967457786&text=https://bangles.org.in/bangles-product?product_no=101&size=5MM&cat=POLA&app_absent=0" >
                         <i className="fab fa-whatsapp" />
                     </Button>
                    </div>
                  </CardFooter> */}
                  <CardFooter className={classes.justifyContentBetween}>
                    <div className={classes.price}>
                      <Button
                        color="danger"
                        onClick={() =>
                          history.push({
                            pathname: `/bangles-product`,
                            state: prod.id,
                          })
                        }
                      >
                        Buy Now
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
        {/* {products ?
          <h4><b>Bangles with the best standard</b> :- We are one of the top manufacturers and retailers in India, having hundreds of satisfied and happy clients from India and around the globe. We manufacture bangles of different models, sizes, and colors like Micro gold plated Rose gold, silver plated, 2 tone & 3 tone 7Seminario bangles at very affordable low prices.</h4> : ""}
        <GridItem
          md={3}
          sm={3}
          className={classNames(classes.mlAuto, classes.mrAuto)}
        >
          <Button round color="danger" onClick={LoadMore}>
            Load more...
          </Button>
        </GridItem> */}
      </div>
    </div>

    // </div>
  );
}
