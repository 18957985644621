/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Danger from "components/Typography/Danger.js";
import MWBLES from "assets/img/examples/MWBLES.jpg";
import MRBLES from "assets/img/examples/MRBLES.jpg";
import ABCD from "assets/img/examples/ABCD.jpg";
import MaharashtrianGreenPolaBangles from "assets/img/examples/MaharashtrianGreenPolaBangles.jpg";
import GoldPlatedAcrylicOdishaPolaBangles from "assets/img/examples/GoldPlatedAcrylicOdishaPolaBangles.jpg";
import FancyMaroonGoldPlatedPolaBangles from "assets/img/examples/FancyMaroonGoldPlatedPolaBangles.jpg";
import GoldPlatedBengalAcrylicPolaBangles from "assets/img/examples/GoldPlatedBengalAcrylicPolaBangles.jpg";
import IconButton from '@material-ui/core/IconButton';
import ReactGa from 'react-ga'
import sectionInterestedStyle from "assets/jss/material-kit-pro-react/views/blogPostsSections/sectionInterestedStyle.js";

const useStyles = makeStyles(sectionInterestedStyle);

export default function PolaPosts() {

    function sayHi() {
        console.log("Hello!");
      }

    const ClickHandler = () => {
      ReactGa.event({
        category:'5mm Gold plated bangles',
        action: 'Click on the landing page',
        // country: 'India',
      })
    }

  const classes = useStyles();
  return (
      <div>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader image plain>
              <a href="#pablo">
                <img src={MWBLES} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + MWBLES + ")",
                  opacity: "1",
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                {/* <h6>ODISHA</h6> */}
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="#pablo">
                5 MM Gold plated Pola Bangles
                </a>
              </h4>
              <p className={classes.description}>
              2Pcs set Minimum order quantity should be 210 Dozens,
               size available from 2x2 up to 2x10, in all designs.
                <a href="/bangles-product?id=272&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated" onClick={ClickHandler}> Buy Now </a>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=272&size=5MM&cat=POLA&app_absent=0"
                //  href="/bangles-product?id=272&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated"
                // {`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                // href="https://api.whatsapp.com/send/?phone=919967457786&text=https://inshabangles.com/product-page.php?product_no=145&size=5MM&cat=POLA&app_absent=0"
                 target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
                  </IconButton></a>              
              </p>     
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={MRBLES} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + MRBLES + ")",
                  opacity: "1",
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                {/* <h6>KARNATAKA</h6> */}
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="#pablo">
                5 MM Gold plated Pola Bangles
                </a>
              </h4>
              <p className={classes.description}>
              2Pcs set Minimum order quantity should be 210 Dozens,
               size available from 2x2 up to 2x10, in all designs.
                <a href="/bangles-product?id=397&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated"  onClick={ClickHandler}> Buy Now </a>
                 <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=397&size=5MM&cat=POLA&app_absent=0"
                //  {`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                  target="_blank"><IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
                  </IconButton></a>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4} lg={4} xl={4}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={ABCD} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + ABCD + ")",
                  opacity: "1",
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                {/* <h6>
                 
                   BENGAL
                </h6> */}
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="#pablo">
                5 MM Gold plated Pola Bangles
                </a>
              </h4>
              <p className={classes.description}>
              2Pcs set Minimum order quantity should be 210 Dozens,
                size available from 2x2 up to 2x10, in all designs.
                <a href="/bangles-product?id=396&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated"  onClick={ClickHandler}> Buy Now </a>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=396&size=5MM&cat=POLA&app_absent=0"
                // {`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                 target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
                  </IconButton></a>  
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>



      <GridContainer>
        <GridItem xs={12} sm={3} md={3} lg={3} xl={3}>
          <Card plain blog>
            <CardHeader image plain>
              <a href="#pablo">
                <img src={MaharashtrianGreenPolaBangles} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + MaharashtrianGreenPolaBangles + ")",
                  opacity: "1",
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                {/* <h6>MAHARASHTRA</h6> */}
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="#pablo">
                Maharashtrian Green Pola Bangles
                </a>
              </h4>
              <p className={classes.description}>
                  5/3MM Gold plated Pola Bangles
                  6 pcs in 1 set, 6 sets in 1 box
                  Minimum Order Quantity: 946 Boxes.       
                <a href="/bangles-product?id=351&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated"  onClick={ClickHandler}> Buy Now </a>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=351&size=5MM&cat=POLA&app_absent=0"
                // {`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                 target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
                  </IconButton></a>  
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3} md={3} lg={3} xl={3}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={GoldPlatedAcrylicOdishaPolaBangles} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + GoldPlatedAcrylicOdishaPolaBangles + ")",
                  opacity: "1",
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                {/* <h6>ODISHA</h6> */}
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="#pablo">
                Gold Plated Acrylic Odisha Pola Bangles
                </a>
              </h4>
              <p className={classes.description}>
                 5/3MM Gold plated Pola Bangles
                 6 pcs in 1 set, 6 sets in 1 box
                 Minimum Order Quantity: 946 Boxes.
                <a href="/bangles-product?id=350&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated"  onClick={ClickHandler}> Buy Now </a>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=350&size=5MM&cat=POLA&app_absent=0"
                // {`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                 target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
                  </IconButton></a>  
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3} md={3} lg={3} xl={3}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={FancyMaroonGoldPlatedPolaBangles} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + FancyMaroonGoldPlatedPolaBangles + ")",
                  opacity: "1",
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h6>
                 
                  {/* GUJRAT */}
                </h6>
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="#pablo">
                Fancy Maroon Gold Plated Pola Bangles
                </a>
              </h4>
              <p className={classes.description}>
              5/3MM Gold plated Pola Bangles
              6 pcs in 1 set, 6 sets in 1 box
              Minimum Order Quantity: 946 Boxes.
                <a href="/bangles-product?id=444&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated"  onClick={ClickHandler}> Buy Now </a>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=444&size=5MM&cat=POLA&app_absent=0"
                // {`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                 target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
                  </IconButton></a>  
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={3} md={3} lg={3} xl={3}>
          <Card plain blog>
            <CardHeader plain image>
              <a href="#pablo">
                <img src={GoldPlatedBengalAcrylicPolaBangles} alt="..." />
              </a>
              <div
                className={classes.coloredShadow}
                style={{
                  backgroundImage: "url(" + GoldPlatedBengalAcrylicPolaBangles + ")",
                  opacity: "1",
                }}
              />
            </CardHeader>
            <CardBody plain>
              <Danger>
                <h6>
                  
                      {/* BENGAL */}
                </h6>
              </Danger>
              <h4 className={classes.cardTitle}>
                <a href="#pablo">
                Gold Plated Bengal Acrylic Pola Banglese
                </a>
              </h4>
              <p className={classes.description}>
                    3MM Pola Bangles
                    8 pcs in 1 set, 6 sets in 1 box
                    Minimum Order Quantity: 744 Boxes.
                <a href="/bangles-product?id=395&catdesc=Bangles&cat1desc=3MM&Plating=Gold%20Plated"  onClick={ClickHandler}> Buy Now </a>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=395&size=3MM&cat=POLA&app_absent=0"
                // {`https://api.whatsapp.com/send/?phone=+919967457786&text=https://polabangles.com/%20Hello%20I%20am%20rahul&app_absent=0`}
                 target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                     <i className="fab fa-whatsapp" />
                  </IconButton></a>  
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    // </div>
  );
}

  