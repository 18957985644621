/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Favorite from "@material-ui/icons/Favorite";
import IconButton from "@material-ui/core/IconButton";
import Subject from "@material-ui/icons/Subject";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import gucci from "assets/img/examples/gucci.jpg";
import { headApi } from "utils/headApi";
import { adminId } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import logo from "assets/img/examples/apple-pola-bangles-icon.png";
import { localApi } from "utils/headApi";

const useStyles = makeStyles(styles);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PolaSizeMMlist() {
  const [prodList, setProdList] = React.useState([]);
  const [loginModal, setLoginModal] = React.useState(false);

  const classes = useStyles();
  const classPop = useStylPop();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));
  const activeSession = sessionStorage.getItem("sessionData");

  const search = useLocation().search;
  const catdesc = new URLSearchParams(search).get("catdesc");

  useEffect(() => {
    const catdesc = new URLSearchParams(search).get("catdesc");
    const cat1desc = new URLSearchParams(search).get("cat1desc");

    fetch(
      localApi +
        `/product/list/byCatMM?admin_id=2&active=1&catdesc=${catdesc}&cat1desc=${cat1desc}`
    )
      .then((res) => res.json())
      .then((data) => setProdList(data));

    setTimeout(hideAlertPop, 1000);
    setLoginModal(true);
  }, [search]);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Dialog
          style={{ marginTop: "10%" }}
          classes={{
            root: classPop.modalRoot,
            paper: classPop.modal + " " + classPop.modalLogin,
          }}
          open={loginModal}
          TransitionComponent={Transition}
          keepMounted
          // onClose={() => setLoginModal(false)}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          <DialogTitle
            id="login-modal-slide-title"
            disableTypography
            className={classPop.modalHeader}
          >
            <h5>Loading...</h5>
            <DialogContent
              id="login-modal-slide-description"
              className={classPop.modalBody}
            >
              <GridContainer justify="center">
                <Spinner
                  onChange={() => hideAlertPop()}
                  style={{ color: "#f44336" }}
                />
              </GridContainer>
              <br />
            </DialogContent>
          </DialogTitle>
        </Dialog>
        <h2>You may also like :</h2>
        <GridContainer>
          {prodList &&
            prodList.map((prod) => (
              <GridItem md={3} sm={6} key={prod.id}>
                <Card product plain>
                  <CardHeader image plain>
                    <CornerRibbon
                      position={
                        prod.stockSize.length === 1 > 0
                          ? "top-left"
                          : "top-right"
                      } // position="top-right" // OPTIONAL, default as "top-right"
                      fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                      backgroundColor="#f44336" // OPTIONAL, default as "#2c7"
                      containerStyle={{}} // OPTIONAL, style of the ribbon
                      style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                      className="" // OPTIONAL, css class of ribbon
                    >
                      {" "}
                      {prod.stockSize.length === 1 > 0
                        ? "In Stock"
                        : "Make To Order"}
                    </CornerRibbon>
                    <a href="#pablo">
                      <img
                        src={
                          prod.productImage[0].cropImageUrl +
                          "?id=" +
                          prod.productImage[0].imgId
                        }
                        alt="..."
                      />
                      {/* <img src={gucci} alt="..." /> */}
                      {/* {prod.design1.img1active === 1 ? (
                        <img
                          src={imgApi + "" + prod.design1.image1}
                          alt="..."
                        />
                      ) : prod.design2.img2active === 1 ? (
                        <img
                          src={imgApi + "" + prod.design2.image2}
                          alt="..."
                        />
                      ) : prod.design3.img3active === 1 ? (
                        <img
                          src={imgApi + "" + prod.design3.image3}
                          alt="..."
                        />
                      ) : prod.design4.img4active === 1 ? (
                        <img
                          src={imgApi + "" + prod.design4.image4}
                          alt="..."
                        />
                      ) : prod.design5.img5active === 1 ? (
                        <img
                          src={imgApi + "" + prod.design5.image5}
                          alt="..."
                        />
                      ) : prod.design6.img6active === 1 ? (
                        <img
                          src={imgApi + "" + prod.design6.image6}
                          alt="..."
                        />
                      ) : (
                        ""
                      )} */}
                    </a>
                    <p
                      style={{
                        position: "absolute",
                        top: "65%",
                        left: "50%",
                        width: "60px",
                        height: "60px",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <img src={logo} alt="..." />
                      {/* {imgApi + "" + prod.design1.image1 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design2.image2 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design3.image3 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design4.image4 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design5.image5 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design6.image6 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : (
                        ""
                      )} */}
                    </p>
                    <div
                      className={classes.coloredShadow}
                      style={{ backgroundImage: `url(${gucci})`, opacity: 1 }}
                    />
                  </CardHeader>
                  <CardBody className={classes.textCenter} plain>
                    <h5 className={classes.cardTitle}>
                      {prod.productCategory.title} {prod.productParent.title}
                      {" - " + prod.itemCodeNumber + " - " + prod.id}
                    </h5>
                    <p className={classes.cardDescription}>
                      {(prod.readyGram * prod.productSets.pcs).toFixed(4)} Avg
                      Gram Of {prod.productSets.setDesc} {"and "}
                      {prod.productBox.boxDesc}
                      {","} {prod.cartDescription}
                      {"."} <br />
                      {prod.gstCash === true ? (
                            <>
                              <h5>
                                <span style={{ color: "red" }}>
                                  Price ₹ {(prod.taxInclude / prod.productBox.box).toFixed(2) + " Inc."}
                                </span>
                              </h5>
                            </>
                          ) : (
                            <>
                              <h5>
                                <span style={{ color: "black" }}>
                                  Price ₹ {(prod.cashPrice / prod.productBox.box).toFixed(2)}
                                </span>
                              </h5>
                            </>
                          )}
                      {/* {!activeSession ? <h3 style={{ color: "black" }}>
                        Price{" "}
                        <span>
                          ₹{" "}
                          {(
                            prod.taxCalculation.cash / prod.box.box
                          ).toFixed(2)}
                          /-*
                        </span>
                      </h3> : <h3 style={{ color: "black" }}>
                        Price{" "}
                        <span>
                          ₹{" "}
                          {(
                            prod.taxCalculation.cash / prod.box.box
                          ).toFixed(2)}
                          /-*
                        </span>
                      </h3>} */}
                    </p>
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <div className={classes.price}>
                      <Button
                        color="danger"
                        href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                        // &Plating=${prod.plating.description.slice(8)}
                      >
                        Buy Now
                      </Button>
                    </div>
                    {/* <div>
                      <a
                        href={`https://api.whatsapp.com/send/?phone=${prod.whatsapp}&text=https://polabangles.com/bangles-product?id=${prod.id}&app_absent=0`}
                        target="_blank"
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IconButton
                          style={{
                            color: "white",
                            fontSize: "40px",
                            backgroundColor: "#42d429",
                            height: "37px",
                            width: "37px",
                          }}
                        >
                          <i className="fab fa-whatsapp" />
                        </IconButton>
                      </a>
                    </div> */}
                  </CardFooter>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
        {prodList ? (
          <h4>
            <b>Bangles with the best standard</b> :-We are one of the top
            manufacturers and retailers in India, having hundreds of satisfied
            and happy clients from India and around the globe. We manufacture
            bangles of different models, sizes, and colors like Micro gold
            plated Rose gold, silver plated, 2 tone & 3 tone 7Seminario bangles
            at very affordable low prices.
          </h4>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
