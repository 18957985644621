/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { getStates, getCities } from "utils/util";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import BusinessIcon from "@material-ui/icons/Business";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SweetAlert from 'react-bootstrap-sweetalert';
import PolaBanglesHeader from "./PolaBanglesHeader";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import styl from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import style from "assets/jss/material-kit-pro-react/views/sweetAlertStyle.js";
import image from "assets/img/bg7.jpg";
import ReactGa from 'react-ga'
import { headApi } from "utils/headApi";
import { localApi } from "utils/headApi";

const useStyles = makeStyles(signupPageStyle);

const useStyl = makeStyles(styl);
const useStyle = makeStyles(style);

export default function PolaSignUpPage() {
  // { ...rest }

  const [countryList, setCountryList] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [mobileWhatsapp, setMobileWhatsapp] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [companyErr, setCompanyErr] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("in");
  const [cityErr, setCityErr] = React.useState("");
  const [stateCode, setStateCode] = React.useState("");
  const [stateNameErr, setStateNameErr] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [stateSelect, setStateSelect] = React.useState("");
  const [citySelect, setCitySelect] = React.useState("");
  const [checked, setChecked] = React.useState([1]);
  const [termCondition, setTermConditions] = React.useState(false);
  const [termConditionErr, setTermConditionsErr] = React.useState("");
  const [countryName, setCountryName] = React.useState("India");

  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [type, setType] = React.useState("");
  const [typeErr, setTypeErr] = React.useState("");
  const [simpleTypeSelect, setSimpleTypeSelect] = React.useState("");
  const [admin, setAdmin] = React.useState("");
  const [btn, setBtn] = React.useState(false);

  const classDrop = useStyl();
  const classes = useStyles();
  const history = useHistory();
  const classe = useStyle();

  const currentSession = sessionStorage.getItem("sessionData");
  if (currentSession) {
    // history.push("/");
  }

  // const headApi = `https://api.bangles.org.in/api/rest`;

  async function getType() {
    const api = localApi + `/type/list`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.LoginType.filter((item) => item.title !== "Control" && item.title !== "Admin"));
    setType(
      getResult && getResult.TypeList.filter(
        (item) => item.title !== "Control" && item.title !== "Admin" && item.title !== "Crm"
      )
    );
    // setType(getResult.LoginType);
    // console.log(getResult);
  }

  useEffect(() => {
    getType();
  }, []);

  async function getAdmin() {
    const api = localApi + `/admin/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.AdminList);
    setAdmin(getResult && getResult.AdminList);
  }

  useEffect(() => {
    getAdmin();
  }, []);

  async function fetchCountryListCode() {
    const api = localApi + `/countrylistcode/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    setCountryList(getResult && getResult);
  }

  useEffect(() => {
    fetchCountryListCode();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    fetch(localApi + `/register/find/byMobile/${mobile}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result && result.message);
          if (result.message === "Not Found") {
            setMobileErr("")
          } else {
            setMobileErr(result && result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/register/find/byEmail/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // setEmailErr(result && result.message);
          if (result.message === "Not Found") {
            setEmailErr("")
          } else {
            setEmailErr(result && result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleCheckedBox = (e) => {
    if (e.target?.checked) {
      setMobileWhatsapp(mobile);
    } else {
      setMobileWhatsapp("");
    }
    setCheckboxValue(e.target.checked);
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    setFirstNameErr("");
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
    setLastNameErr("");
  };

  const handleCompany = (e) => {
    setCompany(e.target.value);
    setCompanyErr("");
  };

  const handleState = (event) => {
    setStateSelect(event.target.value);
    setStateNameErr("");
  };

  const handleCity = (event) => {
    setCitySelect(event.target.value);
    setCityErr("");
  };

  const handleTypeSimple = (e) => {
    const { value } = e.target;
    setSimpleTypeSelect(value);
    setTypeErr("");
  };

  const Submit = (event) => {
    const getFind = type.find((f) => f.title === simpleTypeSelect);
    const getFindAdmin = admin.find((f) => f.websitename === "bangles.org.in");
    const countryListCode = countryList.find((f) => f.countryCode.toLowerCase() === countryCode);

    let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;

    // console.log(countryListCode);

    // const data = {
    //   websitename: "bangles.org.in",
    //       typeId: getFind.typeId,
    //       type: getFind.title,
    //       profit: getFind.profit,
    //       firstname: firstName,
    //       lastname: lastName,
    //       mobile: mobile,
    //       whatsapp: mobileWhatsapp,
    //       email: email,
    //       company: company,
    //       city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //       state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //       country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
    //       smsMarketing: active,
    //       countryCode: countryListCode.countryCode,
    //       isoNumber: countryListCode.isoNumber,
    //       otp: {
    //         otp: 0,
    //         active: 0
    //       },
    // };

    // console.log(JSON.stringify(data));
    // console.log(getFind.profit);
    // console.log(getFindAdmin.id);

    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("Please Enter First Name");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("Please Enter Last Name");
    }
    if (mobileErr === "Mobile No. Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Number alerady register");
      setSubmitted(true);
    }
    if (emailErr === "Email ID Already Registered") {
      errorCount++;
      alertTypeOps("danger");
      alertMsgOps("Email alerady register");
      setSubmitted(true);
    }
    if (company === "" || !company) {
      errorCount++;
      setCompanyErr("Please Enter Company Name");
    }
    if (citySelect === "" || !citySelect) {
      errorCount++;
      setCityErr("Please Select City Name");
    }
    if (mobile === "" || !mobile || !isValidPhoneNumber(`+${mobile}`)) {
      errorCount++;
      setMobileErr("Please Enter Valid Mobile Number");
    }
    if (email === "" || !email || !emailRegex.test(email)) {
      errorCount++;
      setEmailErr("Please Enter Email Id");
    }
    if (simpleTypeSelect === "" || !simpleTypeSelect) {
      errorCount++;
      setTypeErr("Please Select Type");
    }
    if (stateSelect === "" || !stateSelect) {
      errorCount++;
      setStateNameErr("Please Select State Name");
    }
    if (termCondition === false) {
      errorCount++;
      setTermConditionsErr("Please Select Terms And Conditions");
    }
    if (errorCount === 0) {
      setBtn(true);
      // console.log(firstName);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          websitename: "polabangles.com",
          typeId: getFind.typeId,
          type: getFind.title,
          profit: getFind.profit,
          firstname: firstName,
          lastname: lastName,
          mobile: mobile,
          whatsapp: mobileWhatsapp,
          email: email,
          company: company,
          city: citySelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          state: stateSelect.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          country: countryName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ""),
          smsMarketing: active,
          countryCode: countryListCode.countryCode,
          isoNumber: countryListCode.isoNumber,
          source: "WEBSITE",
          otp: {
            otp: 0,
            active: 0
          },
        }),
      };

      fetch(
        localApi + `/register/add/byAdminId/${getFindAdmin.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully registerd!");
            setSubmitted(true);
          }
        });
    }

    // ReactGa.event({
    //   category: 'Sign up',
    //   action: 'Registration successfully',
    //   // country: 'India',
    // })
    // alert('thank you for visiting the website')
    // console.log(alert);
  };


  const hideAlert = () => {
    setSubmitted(false);
    // if (mobileErr === "Not Found" && emailErr === "Not Found") {
    //   //on success handle code
    //   history.push("/pola-login-page");
    // }
    if (!mobileErr || mobileErr === "") {
      // history.push("/bangles-login");
      if (!emailErr || emailErr === "") {
        history.push("/pola-login-page");
      }
    }
  };

  const mystyle = {
    paddingLeft: 12,
    flex: 1,

  };


  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Sign Up</title>
      </Helmet>
      <PolaBanglesHeader
        fixed
        brand="Home"
        color="transparent"
      //  links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" /> }
      // {...rest}
      //  changeColorOnScroll={{
      //    height: 400,
      //    color: "danger",
      //  }}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>Register</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={5} md={5}>
                      <InfoArea
                        className={classes.infoArea}
                        title="First Order Benefit"
                        description="Box packing charges  free  at first order, if order placed in 12 hours from the time of login *"
                        icon={Timeline}
                        iconColor="danger"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Fully Coded in HTML5"
                        description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                        icon={Code}
                        iconColor="danger"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Built Audience"
                        description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                        icon={Group}
                        iconColor="danger"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={5} md={5}>
                      <div className={classes.textCenter}>
                        <Button justIcon round color="twitter">
                          <i className={classes.socials + " fab fa-twitter"} />
                        </Button>
                        {` `}
                        <Button justIcon round color="dribbble">
                          <i className={classes.socials + " fab fa-dribbble"} />
                        </Button>
                        {` `}
                        <Button justIcon round color="facebook">
                          <i
                            className={classes.socials + " fab fa-facebook-f"}
                          />
                        </Button>
                        {` `}
                        <h4 className={classes.socialTitle}>or be classical</h4>
                      </div>

                      <form className={classes.form}>
                        {submitted ? (
                          <SweetAlert
                            type={alertType}
                            style={{ display: "block", marginTop: "100px" }}
                            title={alertMsg}
                            onConfirm={() => hideAlert()}
                            confirmBtnCssClass={
                              // console.log("alert"),
                              classe.button + " " + classe.success
                            }
                          />
                        ) : null}

                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            placeholder: "First Name...",
                            onChange: (e) => handleFirstName(e),
                            name: "firstName",
                            value: firstName,
                          }}
                          id="firstName"
                          required={true}


                        />
                        <span style={{ color: "red" }}>{firstNameErr}</span>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          id="lastName"
                          inputProps={{
                            onChange: (e) => handleLastName(e),
                            value: lastName,
                            name: "lastName",

                            placeholder: "Last Name...",
                            // name: "lastName",
                            // onChange: (e) => setLastName(e.target.value),// isko alag se function call karo  ok
                            // value: lastName,
                          }}
                          required={true}
                        />
                        {/* id="lastName"
                          id="lastName"
                         name="lastName"
                           value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                             setLastNameErr("");
                          }} */}


                        <span style={{ color: "red" }}>{lastNameErr}</span>
                        <br />
                        <PhoneInput
                          style={mystyle}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid #040000 ",
                            borderRadius: "0",
                            // border:null,
                          }}
                          country={"in"}
                          name="mobile"
                          value={mobile}
                          onChange={(mobile) => {
                            let country_code = document.body.querySelector('div.flag')?.className
                            country_code = country_code && country_code.replace("flag", '')
                            setCountryCode(country_code.trim());
                            handleMobileNumber(mobile);
                            let country_name = document.body.querySelector('div.selected-flag')?.title
                            setCountryName(country_name.slice(0, -6));
                          }}

                          required={true}
                        />
                        <span style={{ color: "red" }}>{mobileErr}</span>
                        <br />
                        <FormControlLabel
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              checked={checkboxValue || false}
                              onChange={(e) => handleCheckedBox(e)}
                              tabIndex={-1}
                              // onClick={() => handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            // checked={checked.indexOf(1) !== -1 ? true : false}
                            />
                          }
                          label={
                            <span style={{ color: "#ff0000" }}>
                              If your WhatsApp Number is same, click on this
                              checkbox.
                            </span>
                          }
                        />
                        <br />

                        <PhoneInput
                          style={mystyle}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid #040000 ",
                            borderRadius: "0",
                          }}
                          country={"in"}
                          name="mobile"
                          value={mobileWhatsapp}
                          onChange={(mobileWhatsapp) => {
                            if (mobile === mobileWhatsapp) {
                              setCheckboxValue(true);
                            } else {
                              setCheckboxValue(false);
                            }
                            setMobileWhatsapp(mobileWhatsapp);
                          }}
                        />

                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          id="company"
                          inputProps={{
                            onChange: (e) => handleCompany(e),
                            value: company,
                            name: "company",
                            placeholder: "Company Name...",
                          }}
                          required={true}
                        />
                        <span style={{ color: "red" }}>{companyErr}</span>
                        {/* <br /> */}
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            onChange: (e) => handleEmail(e),
                            id: "email",
                            value: email,
                            name: "email",
                            placeholder: "Email...",
                          }}
                          required={true}
                        />
                        <span style={{ color: "red" }}>
                          {emailErr}
                        </span>

                        {/* <GridItem style={{ width: "110%" }}> */}
                        <FormControl
                          fullWidth
                          className={classDrop.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classDrop.selectLabel}
                          >
                            Select Type...
                          </InputLabel>

                          <Select
                            MenuProps={{
                              className: classDrop.selectMenu,
                            }}
                            classes={{
                              select: classe.select,
                            }}
                            value={simpleTypeSelect}
                            onChange={handleTypeSimple}
                            required={true}
                            inputProps={{
                              name: "simpleTypeSelect",
                              id: "simple-Type-Select",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classDrop.selectMenuItem,
                              }}
                            >
                              Select Type
                            </MenuItem>
                            {type &&
                              type.map((item) => (
                                <MenuItem
                                  key={item.title}
                                  value={item.title}
                                  classes={{
                                    root: classDrop.selectMenuItem,
                                    selected: classDrop.selectMenuItemSelected,
                                  }}
                                >
                                  {item.title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>
                          {typeErr}
                        </span>
                        {/* <br /> */}
                        <FormControl fullWidth className={classes.selectFormControl}>
                          <InputLabel
                            htmlFor="simple-select"
                            className={classDrop.selectLabel}
                          >
                            Select State...
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classDrop.selectMenu,
                            }}
                            classes={{
                              select: classDrop.select,
                            }}
                            value={stateSelect}
                            onChange={handleState}
                            required={true}
                            inputProps={{
                              name: "stateSelect",
                              id: "simple-select",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classDrop.selectMenuItem,
                              }}
                            >
                              Select State
                            </MenuItem>
                            {getStates(countryCode).map((item) => (
                              <MenuItem
                                key={item.name}
                                value={item.name}
                                onClick={() => setStateCode(item?.state_code)}
                                classes={{
                                  root: classDrop.selectMenuItem,
                                  selected: classDrop.selectMenuItemSelected,
                                }}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>{stateNameErr}</span>
                        <br />
                        <FormControl fullWidth className={classDrop.selectFormControl}>
                          <InputLabel
                            htmlFor="simple-select"
                            className={classDrop.selectLabel}
                          >
                            Select City...
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classDrop.selectMenu,
                            }}
                            classes={{
                              select: classDrop.select,
                            }}
                            value={citySelect}
                            onChange={handleCity}
                            required={true}
                            inputProps={{
                              name: "stateSelect",
                              id: "simple-select",
                            }}
                          >
                            <MenuItem
                              disabled
                              classes={{
                                root: classDrop.selectMenuItem,
                              }}
                            >
                              Select City
                            </MenuItem>
                            {getCities(countryCode, stateCode).map((item) => (
                              <MenuItem
                                key={item.name}
                                value={item.name}
                                //  onClick={() => setStateCode(item?.state_code)}
                                classes={{
                                  root: classDrop.selectMenuItem,
                                  selected: classDrop.selectMenuItemSelected,
                                }}
                              >
                                {item.name}


                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <span style={{ color: "red" }}>{cityErr}</span>
                        <br />
                        <FormControlLabel
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              id="active"
                              value={active}
                              onChange={(e) => {
                                setActive(e.target.checked);
                              }}
                              tabIndex={-1}
                              // onClick={() => handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            // checked={checked.indexOf(1) !== -1 ? true : false}
                            />
                          }
                          label={
                            <span style={{ color: "#ff0000" }}>
                              I would like to recieve the latest news from the
                              Company by phone/whatsapp
                            </span>
                          }
                        />
                        <br />
                        <br />
                        <FormControlLabel
                          required={true}
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                          }}
                          control={
                            <Checkbox
                              id="active"
                              checked={termCondition || false}
                              // value={termCondition}
                              onChange={(e) => {
                                setTermConditions(e.target.checked);
                                setTermConditionsErr("")
                              }}
                              tabIndex={-1}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          label={
                            <span style={{ color: "#e91e63" }}>
                              I agree to the <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a> * and <a href="/privacyPolicy" target="_blank">Privacy Policy</a>.
                            </span>
                          }
                        /><br />
                        <span style={{ color: "red" }}>
                          {termConditionErr}
                        </span>
                        <br />
                        <br />
                        <div className={classes.textCenter}>
                          {btn === true ? (
                            <Button round>Sign Up</Button>
                          ) :
                            <Button round color="danger" onClick={Submit}>
                              Sign Up
                            </Button>}
                          {/* <Button  onClick={ClickHandler}>Click here</Button> */}
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        {/* <PolaBanglesFooter/> */}
      </div>
    </div>
  );
}
