/*eslint-disable*/
import React , { useState, useEffect} from "react";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Danger from "components/Typography/Danger.js";
import IconButton from '@material-ui/core/IconButton';
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";
import PartywearPolaBangles from "assets/img/examples/PartywearPolaBangles.jpg";
import office2hold from "assets/img/examples/office2hold.jpg";
import { headApi } from "utils/headApi";
import ReactGa from 'react-ga'
const useStyles = makeStyles(blogsStyle);

export default function PolaBlog({ ...rest }) {
    const [prodList, setProdList] = React.useState([]);
    const [colorSelect, setColorSelect] = React.useState("0");
    const [sizeSelect, setSizeSelect] = React.useState("0");

    const search = useLocation().search;

    const catdesc = new URLSearchParams(search).get('catdesc');
    const cat1desc = new URLSearchParams(search).get('cat1desc');
    // const cat1id = new URLSearchParams(search).get('cat1id');
    // const get = `https://www.secure.mybangles24.com/api/rest/products/bycat1id?catid=${catid}&cat1id=${cat1id}&userid=1`;
    // const res = fetch(get)
    // .then(res => res.json())
    // .then(data => setProdList(data.ProductsData));
  
    // async function getProd() {
    //     // const get = headApi + `/products/byCatMM?catdesc="${catdesc}"&cat1desc="${cat1desc}"&userid=1`;
    //     const results = await fetch(headApi + `/products/byCatMM?catdesc="${catdesc}"&cat1desc="${cat1desc}"&userid=1`);
    //     const res = await results.json();
    //     setProdList(res.ProductsData);
    // }
  
    // useEffect(() => {
    //     getProd();
    // }, []);

  const classes = useStyles();

  function sayHi() {
    console.log("Hello!");
  }

  const ClickHandler = () => {
    ReactGa.event({
      category:'Red and White Gold Plated Pola Bangles',
      action: 'Click on the landing page',
      // country: 'India',
    })
  }
  
  return (
    <div className="cd-section" {...rest}>
      {/* Blogs 1 START */}
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
          {/* {prodList && prodList.map((prod) => ( */}
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={classes.mlAuto + " " + classes.mrAuto}
            >
              <h2 className={classes.title}>
                {/* Latest Blogposts */}
                </h2>
              <br />
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablito" onClick={(e) => e.preventDefault()}>
                        <img src={office2hold} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${office2hold})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <Danger>
                      <h6 className={classes.cardCategory}></h6>
                    </Danger>
                    <h3 className={classes.cardTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Red and White Gold Plated Pola Bangles
                      </a>
                    </h3>
                    <p className={classes.description1}>
                      5/3MM Gold plated Pola Bangles
                      6 pcs in 1 set, 6 sets in 1 box
                      Minimum Order Quantity: 946 Boxes.
                      <a href="/bangles-product?id=277&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated" 
                      // onClick={(e) => e.preventDefault()}
                      onClick={ClickHandler}
                      >
                        {" "}
                        Buy Now{" "}
                      </a>
                      <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=277&size=5MM&cat=POLA&app_absent=0"

                        target="_blank"> <IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                           <i className="fab fa-whatsapp" />
                        </IconButton> </a>
                    </p>
                    {/* <p className={classes.author}>
                      by{" "}
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <b>Mike Butcher</b>
                      </a>{" "}
                      , 2 days ago
                    </p> */}
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <Danger>
                      <h6 className={classes.cardCategory}>
                      
                        {/* INDIA */}
                      </h6>
                    </Danger>
                    <h3 className={classes.cardTitle}>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      Party wear Pola Bangles
                      </a>
                    </h3>
                    <p className={classes.description1}>
                        3MM Pola Bangles
                        4pcs in 1 set, 12 sets in 1 box
                        Minimum Order Quantity: 744 Boxes.
                      <a href="/bangles-product?id=174&catdesc=Bangles&cat1desc=3MM&Plating=Gold%20Plated"
                          //    onClick={(e) => e.preventDefault()}
                            onClick={ClickHandler}
                       >
                        {" "}
                        Buy Now{" "}
                      </a>
                      <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=174&size=5MM&cat=POLA&app_absent=0"

                     target="_blank"><IconButton style={{ color:"white" ,fontSize:"40px" , backgroundColor:"#42d429" , height:"35px", width:"35px" }} onPress={sayHi}>
                            <i className="fab fa-whatsapp" />
                     </IconButton> </a>
                    </p>
                    {/* <p className={classes.author}>
                      by{" "}
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <b>Mike Butcher</b>
                      </a>{" "}
                      , 2 days ago
                    </p> */}
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="#pablito" onClick={(e) => e.preventDefault()}>
                        <img src={PartywearPolaBangles} alt="..." />
                      </a>
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${PartywearPolaBangles})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
        {/* //   ))}   */}
          </GridContainer>
        </div>
      </div>
     
    </div>
  );
}