/*eslint-disable*/
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import BanglesPricingPage from 'views/MyCompo/BanglesPricingPage';
import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingStyle.js";
import { cardBangleTitle } from "assets/jss/material-kit-pro-react";

const style = {
  cardBangleTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};
const useStyles = makeStyles(pricingStyle);
const useStyl = makeStyles(style);

export default function Pricing() {
    const classes = useStyles();
    const [tags, setTags] = React.useState(["pizza"]);
    const classe = useStyl();
    const handleTags = regularTags => {
      setTags(regularTags);
    };
    return (  
      <div>
      <Parallax
        image={require("assets/img/bg2.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Let{"'"}s get started</h1>
              <h4>
                To get started, you will need to choose a plan for your needs.
                You can opt in for the monthly of annual options and go with one
                fo the three listed below.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <BanglesPricingPage />
          <hr />
          {/* <SectionFeatures /> */}
        </div> 
        </div>
        </div>   
   
      );
    };




      
    








