/*eslint-disable*/
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

// pages for this product
// import AboutUsPage from 'views/AboutUsPage/AboutUsPage.js'
// import BlogPostPage from 'views/BlogPostPage/BlogPostPage.js'
// import BlogPostsPage from 'views/BlogPostsPage/BlogPostsPage.js'
// import ComponentsPage from 'views/ComponentsPage/ComponentsPage.js'
// import ContactUsPage from 'views/ContactUsPage/ContactUsPage.js'
// import EcommercePage from 'views/EcommercePage/EcommercePage.js'
// import LandingPage from 'views/LandingPage/LandingPage.js'
// import LoginPage from 'views/LoginPage/LoginPage.js'
// import PresentationPage from 'views/PresentationPage/PresentationPage.js'
// import PricingPage from 'views/PricingPage/PricingPage.js'
// import ProfilePage from 'views/ProfilePage/ProfilePage.js'
// import ProductPage from 'views/ProductPage/ProductPage.js'
// import SectionsPage from 'views/SectionsPage/SectionsPage.js'
// import ShoppingCartPage from 'views/ShoppingCartPage/ShoppingCartPage.js'
// import SignupPage from 'views/SignupPage/SignupPage.js'
// import ErrorPage from 'views/ErrorPage/ErrorPage.js'
// import  { BanglesLandingpage } from "views/BanglesLandingPage/BanglesLandingpage.js";
import Pricing from "views/MyCompo/Pricing.js";
import BanglesLandingpage from "views/MyCompo/BanglesLandingpage.js";
import ProductList from "views/MyCompo/ProductList";
import OfflineProductList from "views/MyCompo/OfflineProductList";
import ProductsPage from "views/MyCompo/ProductsPage";
import BanglesSizeGuide from "views/MyCompo/BanglesSizeGuide";
import PolaLoginPage from "views/MyCompo/PolaLoginPage";
import PolaSignUpPage from "views/MyCompo/PolaSignUpPage";
import SizeMMlist from "views/MyCompo/SizeMMlist";
import PolaBlog from "views/MyCompo/PolaBlog";
import PolaPosts from "views/MyCompo/PolaPosts";
import BanglesContactpage from "views/MyCompo/BanglesContactpage";
// import Contact from "views/MyCompo/Contact"
// import PolaCartPage from 'views/MyCompo/PolaCartPage';
import EditProfile from "views/MyCompo/Profile/EditProfile";
import AddAddress from "views/MyCompo/Profile/AddAddress";
import UpdateAddress from "views/MyCompo/Profile/UpdateAddress";
import GeneratePassword from "views/MyCompo/Profile/GeneratePassword";
import PolaErrorPage from "views/MyCompo/PolaErrorPage";
import MakingDetails from "views/MyCompo/MakingDetails";
import AboutUsPage from "views/MyCompo/AboutUsPage/AboutUsPage";
import Cart from "views/MyCompo/Carts/Cart";
import CartShippingAddress from "views/MyCompo/Carts/CartShippingAddress";
import AddCart from "views/MyCompo/Carts/AddCart";
import CartView from "views/MyCompo/Carts/CartView";
import MyOrder from "views/MyCompo/Carts/MyOrder";
import OrderView from "views/MyCompo/Carts/OrderView";
import OrderViewPrint from "views/MyCompo/Carts/OrderViewPrint";
import PaymentRecieved from "views/MyCompo/Carts/PaymentRecieved";
import ReactGa from "react-ga";
import CareerField from "views/MyCompo/CareerField";
import PrivacyPolicy from "views/MyCompo/PrivacyPolicy";
import ReturnPolicy from "views/MyCompo/ReturnPolicy";
import ReturnAmount from "views/MyCompo/ReturnAmount";
import RefundPolicy from "views/MyCompo/RefundPolicy";
import TermCondition from "views/MyCompo/Term&Condition";
import License from "views/MyCompo/License";
import ProductStock from "views/MyCompo/ProductStock";
import OrderStock from "views/MyCompo/OrderStock";
// import React, { useEffect, useState } from "react";

function App() {
  var hist = createBrowserHistory();

  useEffect(() => {
    ReactGa.initialize("UA-209239011-1");

    ReactGa.pageview(window.location.pathname + window.location.search);
    // ReactGa.pageview('/');
  }, []);

  return (
    <Router history={hist}>
      <Switch>
        {/* <Route path="/about-us" component={AboutUsPage} /> */}
        {/* <Route path="/blog-post" component={BlogPostPage} /> */}
        {/* <Route path="/blog-posts" component={BlogPostsPage} /> */}
        {/* <Route path="/components" component={ComponentsPage} /> */}
        {/* <Route path="/contact-us" component={ContactUsPage} /> */}
        {/* <Route path="/ecommerce-page" component={EcommercePage} /> */}
        {/* <Route path="/landing-page" component={LandingPage} /> */}
        {/* <Route path="/login-page" component={LoginPage} /> */}
        {/* <Route path="/pricing" component={PricingPage} /> */}
        {/* <Route path="/profile-page" component={ProfilePage} /> */}
        {/* <Route path="/product-page" component={ProductPage} /> */}
        {/* <Route path="/sections" component={SectionsPage} /> */}
        {/* <Route path="/shopping-cart-page" component={ShoppingCartPage} /> */}
        {/* <Route path="/signup-page" component={SignupPage} /> */}
        {/* <Route path="/error-page" component={ErrorPage} /> */}
        {/* <Route path="/" component={PresentationPage} /> */}
        {/* <Route path="/loginpage" component={PolaLoginPage} /> */}
        <Route exact path="/pricing-page" component={Pricing} />
        <Route path="/return" component={ReturnAmount} />
        <Route exact path="/pola-blog" component={PolaBlog} />
        <Route exact path="/pola-posts" component={PolaPosts} />
        <Route exact path="/product-list" component={ProductList} />
        <Route
          exact
          path="/offline-product-list"
          component={OfflineProductList}
        />
        <Route exact path="/bangles-product" component={ProductsPage} />
        <Route exact path="/bangles-size-guide" component={BanglesSizeGuide} />
        <Route exact path="/pola-login-page" component={PolaLoginPage} />
        <Route exact path="/pola-signup-page" component={PolaSignUpPage} />
        <Route exact path="/bangles-size-mm-list" component={SizeMMlist} />
        <Route exact path="/contact-us" component={BanglesContactpage} />
        <Route exact path="/cart" component={Cart} />
        <Route
          exact
          path="/cart-shipping-address"
          component={CartShippingAddress}
        />
        <Route exact path="/add-cart" component={AddCart} />
        <Route exact path="/cart-view" component={CartView} />
        <Route exact path="/my-order" component={MyOrder} />
        <Route exact path="/order-view" component={OrderView} />
        <Route exact path="/order-view-print" component={OrderViewPrint} />
        <Route exact path="/payment-recieved" component={PaymentRecieved} />
        <Route exact path="/edit-profile" component={EditProfile} />
        <Route path="/add-address" component={AddAddress} />
        <Route path="/edit-update-address" component={UpdateAddress} />
        <Route exact path="/making-details" component={MakingDetails} />
        <Route exact path="/edit-password" component={GeneratePassword} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route path="/careers" component={CareerField} />
        <Route path="/privacyPolicy" component={PrivacyPolicy} />
        <Route path="/return-policy" component={ReturnPolicy} />
        <Route path="/refund-policy" component={RefundPolicy} />
        <Route path="/terms-and-conditions" component={TermCondition} />
        <Route path="/license" component={License} />
        <Route path="/todays-deal" component={ProductStock} />
        <Route path="/order-stock" component={OrderStock} />
        <Route exact path="/error-page" component={PolaErrorPage} />
        <Route exact path="/" component={BanglesLandingpage} />
      </Switch>
    </Router>
  );
}

export default App;
