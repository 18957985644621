import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import dayjs from "dayjs";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "components/Table/Table.js";
import Close from "@material-ui/icons/Close";
import { ToWords } from "to-words";
import { IoLogoWhatsapp } from "react-icons/io";
import { ImMobile } from "react-icons/im";
import { MdEmail } from "react-icons/md";
import { TiPhoneOutline } from "react-icons/ti";
// import DialogActions from "@material-ui/core/DialogActions";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import CustomInput from "components/CustomInput/CustomInput.js";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import { FiPhoneCall } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CardFooter from "components/Card/CardFooter.js";
import SweetAlert from "react-bootstrap-sweetalert";
import logo from "assets/img/apple-pola-bangles-icon.png";

// import BillHeader from "views/InshaBanglesPage/Cart/BillHeader";
// import ClientDetails from "views/InshaBanglesPage/Cart/ClientDetails";

import invoiceStyle from "assets/jss/material-kit-pro-react/views/invoiceTableStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";
import headerStyle from "assets/jss/material-kit-pro-react/views/invoiceStyle";

// import axios from "axios";
// import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(invoiceStyle);
const useStyle = makeStyles(listCart);
const useStylPop = makeStyles(popOver);
const useStyleAlert = makeStyles(style);
const useStyleHeader = makeStyles(headerStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function OrderViewPrint() {
  const [viewCart, setViewCart] = React.useState([]);
  const [adminAddress, setAdminAddress] = React.useState([]);
  const [regAddress1, setRegAddress1] = React.useState([]);
  const [regAddress2, setRegAddress2] = React.useState([]);
  const [termsClient, setTermsClient] = React.useState([]);
  const [terms, setTerms] = React.useState([]);
  const [ordList, setOrdList] = React.useState("");
  const [branch, setBranch] = React.useState("");
  const [cartLength, setCartLength] = React.useState("");

  const [cartId, setCartId] = React.useState();
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);

  const [invoiceDate, setInvoiceDate] = React.useState("");

  const [uniPer, setUniPer] = React.useState([]);
  const [newTaxable, setNewTaxable] = React.useState([]);
  const [newIgst, setNewIgst] = React.useState([]);
  const [newCgst, setNewCgst] = React.useState([]);
  const [newSgst, setNewSgst] = React.useState([]);
  const [newTotalTax, setNewTotalTax] = React.useState([]);
  const [finalAmt, setFinalAmt] = React.useState();

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classPop = useStylPop();
  const classeAlert = useStyleAlert();
  const classHeader = useStyleHeader();

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const client = new URLSearchParams(search).get("client");

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const termsDefault = [
    {
      id: 1,
      discription: "Goods once sold will not be taken back.",
      type: "Tax Invoice",
    },
    {
      id: 2,
      discription:
        "Interest @ 18% p.a. will be charged if the payment is not made within the stipulated time.",
      type: "Tax Invoice",
    },
    {
      id: 3,
      discription: "Subject to 'Maharashtra' Jurisdiction only.",
      type: "Tax Invoice",
    },
  ];

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(get);
    const res = await results.json();
    setAdminAddress(
      res &&
        res.filter(
          (a) => a.addressType === "GSTIN ADDRESS" && a.active === true
        )
    );
    // console.log(res);
  }

  async function getOrderList() {
    const getOrder =
      localApi + `/orders/list/byAdminId/${userObj.admin.id}/${client}/${id}`;
    const resOrder = await fetch(getOrder);
    const resultOrder = await resOrder.json();
    // console.log(resultOrder);
    setOrdList(resultOrder && resultOrder);
    // console.log(resultOrder.dateOfInvoice);
    setInvoiceDate(resultOrder.dateOfInvoice);
    const gstAddress = resultOrder.deliveryAddress.filter((f) =>
      f && f.addressType === "GSTIN ADDRESS"
        ? f.addressType === "GSTIN ADDRESS"
        : f.addressType === "AADHAR ADDRESS"
        ? f.addressType === "AADHAR ADDRESS"
        : ""
    );
    const shippingAddress = resultOrder.deliveryAddress.filter(
      (f) => f.addressType != "GSTIN ADDRESS"
    );
    setRegAddress1(gstAddress[0]);
    setRegAddress2(shippingAddress[0]);
    setBranch(resultOrder.transbranch);

    const multiCartId = resultOrder.ordersCart.map((i) => {
      return i.cartId;
    });

    const getCart =
      localApi + `/cart/list/byAdminId/${userObj && userObj.admin.id}`;
    const resCart = await fetch(getCart);
    const resultCart = await resCart.json();
    const ordersCart = resultCart.filter(
      (f) => f.active === true && multiCartId.includes(f.id)
    );

    setCartLength(multiCartId.length);
    setViewCart(ordersCart);

    // var converter = require("number-to-words");
    const fAmount = Math.round(
      ordersCart &&
        ordersCart.reduce(
          (a, b) => a + (b.amount + b.cartPackCharge * b.cartQuantity),
          0
        )
    );

    const toWords = new ToWords();
    let words = toWords.convert(fAmount);
    // console.log(words);
    setFinalAmt(words && words);

    const tax = ordersCart && ordersCart.map((m) => m.taxsumary);
    const taaxId = tax.map((m) => {
      return m.taxId;
    });
    // console.log(taaxId);

    const uniquePer = [];
    tax &&
      tax.map((x) =>
        uniquePer.filter((a) => a.taxType == x.taxType).length > 0
          ? null
          : uniquePer.push(x)
      );
    setUniPer(uniquePer && uniquePer);

    const tempTaxable = [];
    const tempIgst = [];
    const tempCgst = [];
    const tempSgst = [];
    const tempTotalTax = [];
    Promise.all(
      tax.map(async (id) => {
        const response = await fetch(
          localApi +
            `/taxSumary/list/byAdminIdAndTaxType/${userObj.admin.id}?taxType=${id.taxType}`
        );
        const data = await response.json();
        const d = data.filter((f) => taaxId.includes(f.taxId));
        // console.log(d);
        // console.log(data);
        tempTaxable.push(d && d.reduce((a, b) => a + b.taxableAmount, 0));
        tempIgst.push(d && d.reduce((a, b) => a + b.igst, 0));
        tempCgst.push(d && d.reduce((a, b) => a + b.cgst, 0));
        tempSgst.push(d && d.reduce((a, b) => a + b.sgst, 0));
        tempTotalTax.push(d && d.reduce((a, b) => a + b.totalTax, 0));
      })
    ).then(() => {
      var newTaxable = [];
      var newIgst = [];
      var newCgst = [];
      var newSgst = [];
      var newTotalTax = [];

      // console.log(tempTaxable);

      var newTaxable = tempTaxable.filter(function (elem, pos) {
        return tempTaxable.indexOf(elem) == pos;
      });
      setNewTaxable(newTaxable && newTaxable);
      // console.log(newTaxable);

      var newIgst = tempIgst.filter(function (elem, pos) {
        return tempIgst.indexOf(elem) == pos;
      });
      setNewIgst(newIgst && newIgst);
      // console.log(newIgst);

      var newCgst = tempCgst.filter(function (elem, pos) {
        return tempCgst.indexOf(elem) == pos;
      });
      setNewCgst(newCgst && newCgst);
      // console.log(newCgst);

      var newSgst = tempSgst.filter(function (elem, pos) {
        return tempSgst.indexOf(elem) == pos;
      });
      setNewSgst(newSgst && newSgst);
      // console.log(newSgst);

      var newTotalTax = tempTotalTax.filter(function (elem, pos) {
        return tempTotalTax.indexOf(elem) == pos;
      });
      setNewTotalTax(newTotalTax && newTotalTax);
      // console.log(newTotalTax);
    });
  }

  async function getProd() {
    const get = localApi + `/adminAddress/list/byAdminId/${userObj.admin.id}`;
    const results = await fetch(get);
    const res = await results.json();
    setAdminAddress(
      res &&
        res.filter(
          (a) => a.addressType === "GSTIN ADDRESS" && a.active === true
        )
    );
    // console.log(res);
  }

  async function getTerms() {
    const get =
      localApi +
      `/termsAndCondition/byAdminAndClientId/${userObj.admin.id}/${client}`;
    const results = await fetch(get);
    const res = await results.json();
    // console.log(res);
    setTermsClient(res && res);
  }

  useEffect(() => {
    getProd();
    getOrderList();
    getTerms();
  }, []);

  const handleRemove = (c) => {
    setSubmitteds(true);
    setCartId(c);
  };

  const handleConfirm = () => {
    // console.log(cartId.id);
    const cartMulti = viewCart.filter((i) => {
      return i.id !== cartId.id;
    });

    // console.log(cartMulti.length);

    const cartMid = cartMulti.map((i) => {
      return i.id;
    });

    // console.log(cartMid);

    const cartClientid = viewCart.map((i) => {
      return i.cId;
    });

    // console.log(viewCart);

    // console.log(cartClientid[0]);

    fetch(
      localApi +
        `/cart/update/byAdminAndCartIdAndFalse/${userObj.admin.id}/${cartId.id}`,
      {
        method: "PUT",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Data Updated Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully !!!");
          setSubmittedConfirm(true);

          const requestOptions = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              cartId: cartMid[0].toString(),
              clientId: cartClientid[0].toString(),
              multiCartId: cartMid,
              amount: Math.round(
                cartMulti.reduce(
                  (a, b) => a + b.amount + b.cartPackCharge * b.cartQuantity,
                  0
                )
              ),
              advance: Math.round(
                cartMulti.reduce(
                  (a, b) =>
                    a +
                    b.metalGram *
                      b.cartSets.pcs *
                      b.cartBox.box *
                      b.productSize.grandTotal *
                      (b.cartRawPrice.price + b.cartRawPrice.different),
                  0
                )
              ),
              totalBox: cartMulti.reduce(
                (a, b) => a + b.productSize.grandTotal,
                0
              ),
              totalCarton: cartMulti.reduce(
                (a, b) => parseInt(a) + parseInt(b.cartQuantity),
                0
              ),
            }),
          };

          // console.log(requestOptions);

          fetch(
            localApi +
              `/orders/update/byAdminAndOrdersId/${userObj.admin.id}/${id}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((data) => {
              console.log(data);
            });
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Cart</title>
      </Helmet>
      {submitted ? (
        <SweetAlert
          type={alertType}
          style={{ display: "block", marginTop: "-100px" }}
          title={alertMsg}
          onConfirm={() => hideAlert()}
          confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
        />
      ) : null}
      {submitteds ? (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "100px" }}
          title="Are you sure?"
          onConfirm={handleConfirm}
          onCancel={() => hideAlerts()}
          confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          cancelBtnCssClass={classeAlert.button + " " + classeAlert.danger}
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        />
      ) : null}

      {submittedConfirm ? (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "100px" }}
          title="Deleted!"
          onConfirm={() => hideAlerts()}
          onCancel={() => hideAlerts()}
          confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
        >
          Your imaginary file has been deleted.
        </SweetAlert>
      ) : null}
      <div
        className={classes.container}
        style={{ border: "1px solid #000000" }}
      >
        <GridContainer className={classHeader.borderLine}>
          <GridItem md={2} sm={2} xs={2}>
            <img
              src={userObj.admin.logo != null ? localApi + `/admin/logoImageDownload/${userObj.admin.logo}?id=${userObj.admin.id}` : logo}
              style={{ marginTop: "10px", height: "125px" }}
              alt="insha"
            />
          </GridItem>
          <GridItem
            md={8}
            sm={8}
            xs={8}
            className={
              classHeader.mlAuto +
              " " +
              classHeader.mrAuto +
              " " +
              classHeader.textCenter
            }
          >
            <span className={classHeader.invoiceName}>
              {ordList.gstCash === true
                ? ordList.invoiceNumber === "0"
                  ? "SALES ORDER"
                  : "TAX INVOICE"
                : ordList.invoiceNumber === "0"
                ? "SALES ORDER"
                : "CASH MEMO"}
            </span>
            <br />
            <span className={classHeader.companyName}>
              {adminAddress.map((a) => a.company)}
            </span>
            <br />
            <div className={classHeader.space}>
              <span className={classHeader.address}>
                {adminAddress.map((a) => a.plotHouseNo)}{" "}
                {adminAddress.map((a) => a.areaColony)}{" "}
                {adminAddress.map((a) => a.landMark)}{" "}
                {adminAddress.map((a) => a.road)}
                <br />
                {adminAddress.map((a) => a.city)} {"-"}{" "}
                {adminAddress.map((a) => a.zipCode)},{" "}
                {adminAddress.map((a) => a.state)}, INDIA.
                <br />{" "}
                <strong className={classHeader.gst}>
                  GSTIN : {adminAddress.map((a) => a.gstIn)}
                </strong>{" "}
              </span>
              <br />
              <span className={classHeader.mobileTel}>
                <ImMobile /> {adminAddress.map((a) => a.mobile)}{" "}
                <IoLogoWhatsapp /> {adminAddress.map((a) => a.whatsApp)}{" "}
                <MdEmail /> {userObj.admin.email}
              </span>
            </div>
          </GridItem>
          <GridItem md={2} sm={2} xs={2} className={classHeader.originalCopy}>
            <span>Original Copy</span>
          </GridItem>
        </GridContainer>
        {/* <BillHeader /> */}
        <GridContainer className={classHeader.borderLine}>
          <GridItem
            md={6}
            sm={6}
            xs={6}
            className={classHeader.space + " " + classHeader.verticalLine}
          >
            <span className={classHeader.invoiceDetailsHead}>Billed to :-</span>
            <br />
            <span className={classHeader.invoicedetailCom}>
              {regAddress1 && regAddress1.company
                ? regAddress1.company
                : regAddress1 &&
                  regAddress1.firstName + " " + regAddress1 &&
                  regAddress1.lastName}
            </span>
            <br />
            <span className={classHeader.invoicedetail}>
              {regAddress1 && regAddress1.plotHouseNo}{" "}
              {regAddress1 && regAddress1.areaColony}
            </span>
            <br />
            <span className={classHeader.invoicedetail}>
              {regAddress1 && regAddress1.landMark}{" "}
              {regAddress1 && regAddress1.road}{" "}
              {regAddress1 && regAddress1.zipCode}
            </span>{" "}
            <br />
            <span className={classHeader.invoicedetail}>
              {regAddress1 && regAddress1.city}{" "}
              {regAddress1 && regAddress1.state}
            </span>
            <br />
            <span className={classHeader.invoicedetails}>
              {regAddress1 && regAddress1.mobile ? <ImMobile /> : ""}{" "}
              {regAddress1 && regAddress1.mobile ? regAddress1.mobile : ""}{" "}
              {regAddress1 && regAddress1.whatsApp ? <IoLogoWhatsapp /> : ""}{" "}
              {regAddress1 && regAddress1.whatsApp ? regAddress1.whatsApp : ""}
            </span>
            <br />
            <span
              className={classHeader.invoicedetail + " " + classHeader.gstIn}
            >
              {regAddress1 && regAddress1.gstIn ? "GSTIN / UIN" : "Aadhar No."}{" "}
              &nbsp;&nbsp;:{" "}
              {regAddress1 && regAddress1.gstIn
                ? regAddress1.gstIn
                : regAddress1 && regAddress1.aadharNumber}
            </span>
          </GridItem>
          <GridItem md={6} sm={6} xs={6} className={classHeader.space}>
            <span className={classHeader.invoiceDetailsHead}>
              Shipped to :-
            </span>
            <br />
            <span className={classHeader.invoicedetailCom}>
              {regAddress2 && regAddress2.company
                ? regAddress2.company
                  ? regAddress2.company
                  : regAddress2 &&
                    regAddress2.firstName + " " + regAddress2 &&
                    regAddress2.lastName
                : regAddress1.company
                ? regAddress1.company
                : regAddress1 &&
                  regAddress1.firstName + " " + regAddress1 &&
                  regAddress1.lastName}
            </span>
            <br />
            <span className={classHeader.invoicedetail}>
              {regAddress2 && regAddress2.plotHouseNo
                ? regAddress2.plotHouseNo
                : regAddress1 && regAddress1.plotHouseNo}{" "}
              {regAddress2 && regAddress2.areaColony
                ? regAddress2.areaColony
                : regAddress1 && regAddress1.areaColony}
            </span>{" "}
            <br />
            <span className={classHeader.invoicedetail}>
              {regAddress2 && regAddress2.landMark
                ? regAddress2.landMark
                : regAddress1 && regAddress1.landMark}{" "}
              {regAddress2 && regAddress2.road
                ? regAddress2.road
                : regAddress1 && regAddress1.road}{" "}
              {regAddress2 && regAddress2.zipCode
                ? regAddress2.zipCode
                : regAddress1 && regAddress1.zipCode}{" "}
            </span>{" "}
            <br />
            <span className={classHeader.invoicedetail}>
              {regAddress2 && regAddress2.city
                ? regAddress2.city
                : regAddress1 && regAddress1.city}{" "}
              {regAddress2 && regAddress2.state
                ? regAddress2.state
                : regAddress1 && regAddress1.state}
            </span>
            <br />
            <span className={classHeader.invoicedetails}>
              {regAddress2 && regAddress2.mobile ? (
                <ImMobile />
              ) : regAddress1 && regAddress1.mobile ? (
                <ImMobile />
              ) : (
                ""
              )}{" "}
              {regAddress2 && regAddress2.mobile
                ? regAddress2.mobile
                : regAddress1 && regAddress1.mobile
                ? regAddress1.mobile
                : ""}{" "}
              {regAddress2 && regAddress2.whatsApp ? (
                <TiPhoneOutline />
              ) : regAddress1 && regAddress1.whatsApp ? (
                <TiPhoneOutline />
              ) : (
                ""
              )}{" "}
              {regAddress2 && regAddress2.whatsApp
                ? regAddress2.whatsApp
                : regAddress1 && regAddress1.whatsApp
                ? regAddress1.whatsApp
                : ""}
            </span>
            <br />
            <span
              className={classHeader.invoicedetail + " " + classHeader.gstIn}
            >
              {regAddress2 && regAddress2.gstIn
                ? regAddress2.gstIn
                  ? "GSTIN / UIN :"
                  : "Aadhar No. :"
                : regAddress1.gstIn
                ? "GSTIN / UIN :"
                : "Aadhar No. :"}
              {regAddress2 && regAddress2.gstIn
                ? regAddress2.gstIn
                  ? regAddress2.gstIn
                  : regAddress2 && regAddress2.aadharNumber
                : regAddress1.gstIn
                ? regAddress1.gstIn
                : regAddress1 && regAddress1.aadharNumber}
            </span>
          </GridItem>
        </GridContainer>
        {/* <ClientDetails /> */}
        <Table
          tableHead={[
            <span className={classes.tableHead} key="sr">
              Sr.
              {/* <br /> */}
              No.
            </span>,
            <span className={classes.tableHead} key="des">
              Description of Goods
            </span>,
            <span
              className={classes.tableHead + " " + classes.headRight}
              key="hsn"
            >
              HSN/SAC Code
            </span>,
            <span
              className={classes.tableHead + " " + classes.headRight}
              key="ctn"
            >
              Carton
            </span>,
            <span
              className={classes.tableHead + " " + classes.headRight}
              key="qty"
            >
              Quantity
            </span>,
            <span
              className={classes.tableHead + " " + classes.headRight}
              key="pri"
            >
              Price
            </span>,
            <span
              className={classes.tableHead + " " + classes.headRight}
              key="amt"
            >
              Amount
            </span>,
            "",
          ]}
          tableData={[
            ...viewCart.map((c, index) => [
              <span className={classes.listNumber} key={c.id}>
                {index + 1}
              </span>,
              //   <div className={classe.imgContainer} key={c.id}>
              //     Date: {c.createDate.slice(0, -19)}
              //     <img
              //       src={
              //         c.cartImage[0].cropImageUrl + "?id=" + c.cartImage[0].imgId
              //       }
              //       className={classes.img}
              //       style={{ height: "140px" }}
              //     />
              //   </div>,
              <span key={c.id}>
                {/* <h6 className={classes.listDescriptions}>
                  {c.cartChild.map((f) =>
                    f.title === "Plating Gold Plated" ? f.title.slice(8) : ""
                  )}{" "}
                  {c.parent.title} {c.cartRawPrice.title} <br />
                  {c.category.title} with {c.cartPacking.type}
                  <br />
                  {c.readyGram * c.sets.pcs} Avg Gram of {c.sets.setDesc}
                  <br />
                  Sets Price :{" "}
                  {c.gstCash === false
                    ? (c.cashPrice / c.cartBox.box).toFixed(2)
                    : (c.taxInclude / c.cartBox.box).toFixed(2)}
                  /- {c.gstCash === false ? "Cash" : "IncTax"}
                  <br />
                  Metal Rate : ₹{" "}
                  {c.cartRawPrice.price + c.cartRawPrice.different}/- Advance :
                  ₹
                  {Math.round(
                    c.metalGram *
                      c.sets.pcs *
                      c.cartBox.box *
                      c.productSize.grandTotal *
                      (c.cartRawPrice.price + c.cartRawPrice.different)
                  )}
                </h6>
                <br /> */}

                <span>
                  <span className={classes.descParagragh}>
                    IMMITATION BANGLES{" "}
                  </span>
                  <span>{"(Product No. " + c.pId + ")"}</span>
                </span>

                <GridContainer className={classes.sizePadding}>
                  {c.productSize.s1x8 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x8 == null
                        ? ""
                        : c.productSize.s1x8 == 0
                        ? ""
                        : "1x8"}
                      <br />
                      {c.productSize.s1x8 == null
                        ? ""
                        : c.productSize.s1x8 == 0
                        ? ""
                        : c.productSize.s1x8}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s1x10 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x10 == null
                        ? ""
                        : c.productSize.s1x10 == 0
                        ? ""
                        : "1x10"}
                      <br />
                      {c.productSize.s1x10 == null
                        ? ""
                        : c.productSize.s1x10 == 0
                        ? ""
                        : c.productSize.s1x10}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s1x12 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x12 == null
                        ? ""
                        : c.productSize.s1x12 == 0
                        ? ""
                        : "1x12"}
                      <br />
                      {c.productSize.s1x12 == null
                        ? ""
                        : c.productSize.s1x12 == 0
                        ? ""
                        : c.productSize.s1x12}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s1x14 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x14 == null
                        ? ""
                        : c.productSize.s1x14 == 0
                        ? ""
                        : "1x14"}
                      <br />
                      {c.productSize.s1x14 == null
                        ? ""
                        : c.productSize.s1x14 == 0
                        ? ""
                        : c.productSize.s1x14}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x0 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x0 == null
                        ? ""
                        : c.productSize.s2x0 == 0
                        ? ""
                        : "2x0"}
                      <br />
                      {c.productSize.s2x0 == null
                        ? ""
                        : c.productSize.s2x0 == 0
                        ? ""
                        : c.productSize.s2x0}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x2 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x2 == null
                        ? ""
                        : c.productSize.s2x2 == 0
                        ? ""
                        : "2x2"}
                      <br />
                      {c.productSize.s2x2 == null
                        ? ""
                        : c.productSize.s2x2 == 0
                        ? ""
                        : c.productSize.s2x2}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x4 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x4 == null
                        ? ""
                        : c.productSize.s2x4 == 0
                        ? ""
                        : "2x4"}
                      <br />
                      {c.productSize.s2x4 == null
                        ? ""
                        : c.productSize.s2x4 == 0
                        ? ""
                        : c.productSize.s2x4}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x6 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x6 == null
                        ? ""
                        : c.productSize.s2x6 == 0
                        ? ""
                        : "2x6"}
                      <br />
                      {c.productSize.s2x6 == null
                        ? ""
                        : c.productSize.s2x6 == 0
                        ? ""
                        : c.productSize.s2x6}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x8 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x8 == null
                        ? ""
                        : c.productSize.s2x8 == 0
                        ? ""
                        : "2x8"}
                      <br />
                      {c.productSize.s2x8 == null
                        ? ""
                        : c.productSize.s2x8 == 0
                        ? ""
                        : c.productSize.s2x8}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x10 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x10 == null
                        ? ""
                        : c.productSize.s2x10 == 0
                        ? ""
                        : "2x10"}
                      <br />
                      {c.productSize.s2x10 == null
                        ? ""
                        : c.productSize.s2x10 == 0
                        ? ""
                        : c.productSize.s2x10}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x12 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x12 == null
                        ? ""
                        : c.productSize.s2x12 == 0
                        ? ""
                        : "2x12"}
                      <br />
                      {c.productSize.s2x12 == null
                        ? ""
                        : c.productSize.s2x12 == 0
                        ? ""
                        : c.productSize.s2x12}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x14 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x14 == null
                        ? ""
                        : c.productSize.s2x14 == 0
                        ? ""
                        : "2x14"}
                      <br />
                      {c.productSize.s2x14 == null
                        ? ""
                        : c.productSize.s2x14 == 0
                        ? ""
                        : c.productSize.s2x14}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s3x0 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s3x0 == null
                        ? ""
                        : c.productSize.s3x0 == 0
                        ? ""
                        : "3x0"}
                      <br />
                      {c.productSize.s3x0 == null
                        ? ""
                        : c.productSize.s3x0 == 0
                        ? ""
                        : c.productSize.s3x0}
                    </span>
                  ) : (
                    ""
                  )}
                </GridContainer>
                <GridContainer className={classes.sizePadding}>
                  {c.productSize.s1x9 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x9 == null
                        ? ""
                        : c.productSize.s1x9 == 0
                        ? ""
                        : "1x9"}
                      <br />
                      {c.productSize.s1x9 == null
                        ? ""
                        : c.productSize.s1x9 == 0
                        ? ""
                        : c.productSize.s1x9}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s1x11 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x11 == null
                        ? ""
                        : c.productSize.s1x11 == 0
                        ? ""
                        : "1x11"}
                      <br />
                      {c.productSize.s1x11 == null
                        ? ""
                        : c.productSize.s1x11 == 0
                        ? ""
                        : c.productSize.s1x11}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s1x13 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x13 == null
                        ? ""
                        : c.productSize.s1x13 == 0
                        ? ""
                        : "1x13"}
                      <br />
                      {c.productSize.s1x13 == null
                        ? ""
                        : c.productSize.s1x13 == 0
                        ? ""
                        : c.productSize.s1x13}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s1x15 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s1x15 == null
                        ? ""
                        : c.productSize.s1x15 == 0
                        ? ""
                        : "1x15"}
                      <br />
                      {c.productSize.s1x15 == null
                        ? ""
                        : c.productSize.s1x15 == 0
                        ? ""
                        : c.productSize.s1x15}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x1 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x1 == null
                        ? ""
                        : c.productSize.s2x1 == 0
                        ? ""
                        : "2x1"}
                      <br />
                      {c.productSize.s2x1 == null
                        ? ""
                        : c.productSize.s2x1 == 0
                        ? ""
                        : c.productSize.s2x1}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x3 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x3 == null
                        ? ""
                        : c.productSize.s2x3 == 0
                        ? ""
                        : "2x3"}
                      <br />
                      {c.productSize.s2x3 == null
                        ? ""
                        : c.productSize.s2x3 == 0
                        ? ""
                        : c.productSize.s2x3}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x5 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x5 == null
                        ? ""
                        : c.productSize.s2x5 == 0
                        ? ""
                        : "2x5"}
                      <br />
                      {c.productSize.s2x5 == null
                        ? ""
                        : c.productSize.s2x5 == 0
                        ? ""
                        : c.productSize.s2x5}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x7 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x7 == null
                        ? ""
                        : c.productSize.s2x7 == 0
                        ? ""
                        : "2x7"}
                      <br />
                      {c.productSize.s2x7 == null
                        ? ""
                        : c.productSize.s2x7 == 0
                        ? ""
                        : c.productSize.s2x7}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x9 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x9 == null
                        ? ""
                        : c.productSize.s2x9 == 0
                        ? ""
                        : "2x9"}
                      <br />
                      {c.productSize.s2x9 == null
                        ? ""
                        : c.productSize.s2x9 == 0
                        ? ""
                        : c.productSize.s2x9}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x11 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x11 == null
                        ? ""
                        : c.productSize.s2x11 == 0
                        ? ""
                        : "2x11"}
                      <br />
                      {c.productSize.s2x11 == null
                        ? ""
                        : c.productSize.s2x11 == 0
                        ? ""
                        : c.productSize.s2x11}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x13 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x13 == null
                        ? ""
                        : c.productSize.s2x13 == 0
                        ? ""
                        : "2x13"}
                      <br />
                      {c.productSize.s2x13 == null
                        ? ""
                        : c.productSize.s2x13 == 0
                        ? ""
                        : c.productSize.s2x13}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s2x15 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s2x15 == null
                        ? ""
                        : c.productSize.s2x15 == 0
                        ? ""
                        : "2x15"}
                      <br />
                      {c.productSize.s2x15 == null
                        ? ""
                        : c.productSize.s2x15 == 0
                        ? ""
                        : c.productSize.s2x15}
                    </span>
                  ) : (
                    ""
                  )}
                  {c.productSize.s3x1 != 0 ? (
                    <span
                      className={
                        classes.textCenter +
                        " " +
                        classes.sizeSpan +
                        " " +
                        classes.listDescriptionMM
                      }
                    >
                      {c.productSize.s3x1 == null
                        ? ""
                        : c.productSize.s3x1 == 0
                        ? ""
                        : "3x1"}
                      <br />
                      {c.productSize.s3x1 == null
                        ? ""
                        : c.productSize.s3x1 == 0
                        ? ""
                        : c.productSize.s3x1}
                    </span>
                  ) : (
                    ""
                  )}
                </GridContainer>
                {/* <span className={classes.listDescription}>
                    {c && c.name ? c.name + ", " : ""}{" "}
                    {c && c.mycomment ? c.mycomment : ""}
                  </span> */}
              </span>,
              <span
                className={classes.headRight + " " + classes.listNumber}
                key={c.id}
              >
                {c.hsnSacCode}
              </span>,
              <span
                className={classes.headRight + " " + classes.listNumber}
                key={c.id}
              >
                {c.cartQuantity}
              </span>,
              <span
                className={classes.headRight + " " + classes.listNumber}
                key={c.id}
              >
                {c.productSize.grandTotal}
              </span>,
              <span
                className={classes.headRight + " " + classes.listNumber}
                key={c.id}
              >
                ₹{" "}
                {c.gstCash === true
                  ? c.taxInclude.toFixed(2)
                  : c.cashPrice.toFixed(2)}
              </span>,
              <span
                className={classes.headRight + " " + classes.listNumber}
                key={c.id}
              >
                ₹ {Math.round(c.amount)}
              </span>,
              // cartLength != 1 ? (
              //   <Tooltip
              //     id="close1"
              //     title="Cart Remove"
              //     placement="left"
              //     classes={{ tooltip: classe.tooltip }}
              //     key={c.id}
              //   >
              //     <Button
              //       link
              //       className={classe.actionButton}
              //       onClick={() => handleRemove(c)}
              //     >
              //       <Close style={{ color: "red" }} />
              //     </Button>
              //   </Tooltip>
              // ) : (
              //   ""
              // ),
            ]),
            [
              "",
              <span
                className={classes.headLeft + " " + classes.listNumber}
                key={1}
              >
                Cartoon Charges
                <br />
                Grand Total
              </span>,
              "",
              <span key={3}>
                <br />
                <span className={classes.headRight + " " + classes.listNumber}>
                  Carton-
                  {viewCart.reduce((a, b) => a + b.cartQuantity, 0)}
                </span>
              </span>,
              <span>
                <br />
                <span className={classes.headRight + " " + classes.listNumber}>
                  Unit-
                  {viewCart &&
                    viewCart.reduce((a, b) => a + b.productSize.grandTotal, 0)}
                </span>
              </span>,
              "",
              <span key={4}>
                <span className={classes.headRight + " " + classes.listNumber}>
                  ₹
                  {viewCart.reduce(
                    (a, b) => a + b.cartPackCharge * b.cartQuantity,
                    0
                  ) + "/-"}
                </span>
                <br />
                <span className={classes.headRight + " " + classes.listNumber}>
                  ₹
                  {Math.round(
                    viewCart.reduce(
                      (a, b) =>
                        a + b.amount + b.cartPackCharge * b.cartQuantity,
                      0
                    )
                  ) + "/-"}
                </span>
              </span>,
              "",
            ],
          ]}
        />
        {/* <hr /> */}
        <GridContainer className={classes.sizePadding}>
          <span>
            <small
              className={classes.taxDesc}
              style={{ textDecorationLine: "underline" }}
            >
              Tax Rate
            </small>
            <br />
            {uniPer.map((t) => (
              <small className={classes.invoicedetail} key={t.taxId}>
                {t.taxType + "%"}
                <br />
              </small>
            ))}
          </span>
          <span style={{ marginLeft: "15px", marginRight: "15px" }}>
            <small
              className={classes.taxDesc}
              style={{ textDecorationLine: "underline" }}
            >
              Taxable Ami
            </small>
            <br />
            {newTaxable.map((n) => (
              <small className={classes.invoicedetail} key={n}>
                {n.toFixed(3)}
                <br />
              </small>
            ))}
          </span>
          <span style={{ marginRight: "15px" }}>
            {ordList.saleType == 0 ? (
              <span>
                <small
                  className={classes.taxDesc}
                  style={{ textDecorationLine: "underline" }}
                >
                  CGST Amt.
                </small>
              </span>
            ) : (
              <small
                className={classes.taxDesc}
                style={{ textDecorationLine: "underline" }}
              >
                IGST Amt.
              </small>
            )}
            <br />
            {ordList.saleType == 0 ? (
              <span>
                <small className={classes.invoicedetail}>
                  {newCgst.map((n) => (
                    <small className={classes.invoicedetail} key={n}>
                      {n.toFixed(3)}
                      <br />
                    </small>
                  ))}
                </small>
              </span>
            ) : (
              <small className={classes.invoicedetail}>
                {newIgst.map((n) => (
                  <small className={classes.invoicedetail} key={n}>
                    {n.toFixed(3)}
                    <br />
                  </small>
                ))}
              </small>
            )}
          </span>
          <span style={{ marginRight: "15px" }}>
            {ordList.saleType == 0 ? (
              <span>
                <small
                  className={classes.taxDesc}
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  SGST Amt.
                </small>{" "}
              </span>
            ) : (
              ""
            )}
            <br />
            {ordList.saleType == 0 ? (
              <span>
                <small className={classes.invoicedetail}>
                  {newSgst.map((n) => (
                    <small className={classes.invoicedetail} key={n}>
                      {n.toFixed(3)}
                      <br />
                    </small>
                  ))}
                </small>{" "}
              </span>
            ) : (
              ""
            )}
          </span>
          <span>
            <small
              className={classes.taxDesc}
              style={{ textDecorationLine: "underline" }}
            >
              Total Tax
            </small>
            <br />
            {newTotalTax.map((n) => (
              <small className={classes.invoicedetail} key={n}>
                {n.toFixed(3)}
                <br />
              </small>
            ))}
          </span>
        </GridContainer>
        <GridContainer
          // style={{ marginTop: "2px" }}
          className={classes.borderLine}
        >
          <GridItem md={12} xs={12} sm={12}>
            <span className={classes.invoicedetail + " " + classes.gstIn}>
              Amount Chargeable (in words) INR :- {finalAmt} Only
            </span>
          </GridItem>
        </GridContainer>
        <GridContainer
          className={classes.borderLine}
          // style={{ marginTop: "2px" }}
        >
          <GridItem md={12} xs={12} sm={12}>
            <span className={classes.invoicedetail + " " + classes.gstIn}>
              Company's Bank Details:- State Bank of India, INSHA BANGLES, A/c
              No.: 33975373956, SBIN0017293, SHIFT/BIC Code: SBININBB905
            </span>
            <br />
            <span className={classes.invoicedetailTermAddress}>
              Address:- SHOP NO 11, BHOOMI CLASSIC CHS LTD, New Link Rd, Malad
              West, Mumbai, Maharashtra 400064,
            </span>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem
            md={8}
            sm={8}
            xs={8}
            className={classes.spaces}
            style={{
              borderRight: "1px solid black",
            }}
          >
            <span
              className={classes.taxDesc}
              style={{
                textDecorationLine: "underline",
              }}
            >
              Terms & Conditions
            </span>
            <br />
            <span className={classes.taxDesc}>E & O.C</span>
            <ol className={classes.invoicedetailTerm}>
              {termsClient.length != 0
                ? termsClient.map((tc) => <li key={tc.id}>{tc.discription}</li>)
                : termsDefault.map((t) => <li key={t.id}>{t.discription}</li>)}
            </ol>
            {/* <br /> */}
            <span
              className={classes.invoicedetail + " " + classes.gstIn}
              style={{
                float: "right",
                marginTop: "15px",
              }}
            >
              Receiver's Signature:
              <br />
            </span>
            <div className={classes.brand}>
              <Button
                className={classes.print}
                color="danger"
                round
                onClick={() => {
                  window.print();
                  // window.location.href = "/my-order";
                }}
              >
                Print
              </Button>
              <Button
                className={classes.print}
                color="danger"
                round
                href="/my-order"
              >
                Back
              </Button>
            </div>
          </GridItem>
          <GridItem md={4} sm={4} xs={4}>
            <br />
            <div
            // style={{
            //   backgroundImage: `url(${logo})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundPosition: "center",
            //   backgroundSize: "fixed",
            // }}
            >
              <span className={classes.invoicedetailTerm + " " + classes.gstIn}>
                <small
                  className={classes.invoicedetailTerm + " " + classes.gstIn}
                  style={{ marginLeft: "30%" }}
                >
                  for INSHA BANGLES
                </small>
                <br />
                <img 
                src={userObj.admin.signature != null ? localApi + `/admin/signatureImageDownload/${userObj.admin.signature}?id=${userObj.admin.id}` : ""} 
                style={{ marginLeft: "25%" }} />
                <br />
                <small
                  className={classes.invoicedetailTerm + " " + classes.gstIn}
                  style={{ marginLeft: "30%" }}
                >
                  Authorised Signatory
                </small>
              </span>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
