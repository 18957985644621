/* eslint-disable */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";
import myBanglesProject from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";
import SectionLandingpage from "./SectionLandingpage";

const useStyles = makeStyles(myBanglesProject);

export default function SectionPolaBangles({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            {/* Project 2 START */}
            <div className={classes.projects}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                        >  
                         <Muted>
                                {/* <h6>OUR WORK</h6> */}
                            </Muted>
                            <h2 className={classes.title}>
                                Extra Benefit
                            </h2>
                            <h5 className={classes.description}>
                            We make bangles at the lowest making charge rate. We provide additional benefits to clients; we will print the brand name of the client on the boxes for free of cost if the minimum quantity of the order is 4000 boxes or above.
                            </h5>
                            {/* <div className={classes.sectionSpace} />                         */}
                        </GridItem>
                    </GridContainer>                  
                    <GridContainer>
                    < SectionLandingpage/>                      
                    </GridContainer>
                </div>
            </div>
            {/* Project 2 END */}
        </div>
    );
}
