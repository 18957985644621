/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Search from "@material-ui/icons/Search";
import Apps from "@material-ui/icons/Apps";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import { FaUserEdit, FaExpeditedssl, FaAddressBook } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { RiShoppingBagLine } from "react-icons/ri";
// import { MdAddchart } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { headApi } from "utils/headApi";
import { adminId } from "utils/headApi";
import { localApi } from "utils/headApi";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(navbarsStyle);

export default function HeaderLinks(props) {
  const [bangle, setbangle] = React.useState([]);
  const [earing, setEaring] = React.useState([]);
  const [ring, setRing] = React.useState([]);
  const [watch, setWatch] = React.useState([]);
  const [kada, setKada] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [category, setCategory] = React.useState([]);
  const [simpleSelect, SetSimpleSelect] = React.useState("");
  const [cardList, setCardList] = React.useState([]);

  // const easeInOutQuad = (t, b, c, d) => {
  //     t /= d / 2;
  //     if (t < 1) return (c / 2) * t * t + b;
  //     t--;
  //     return (-c / 2) * (t * (t - 2) - 1) + b;
  // };

  // const smoothScroll = (e, target) => {
  //     if (window.location.pathname === "/sections") {
  //         var isMobile = navigator.userAgent.match(
  //             /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  //         );
  //         if (isMobile) {
  //             // if we are on mobile device the scroll into view will be managed by the browser
  //         } else {
  //             e.preventDefault();
  //             var targetScroll = document.getElementById(target);
  //             scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  //         }
  //     }
  // };
  // const scrollGo = (element, to, duration) => {
  //     var start = element.scrollTop,
  //         change = to - start,
  //         currentTime = 0,
  //         increment = 20;

  //     var animateScroll = function () {
  //         currentTime += increment;
  //         var val = easeInOutQuad(currentTime, start, change, duration);
  //         element.scrollTop = val;
  //         if (currentTime < duration) {
  //             setTimeout(animateScroll, increment);
  //         }
  //     };
  //     animateScroll();
  // };
  // var onChangeSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();

  const userSession = JSON.parse(sessionStorage.getItem("sessionData"));
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  useEffect(() => {
    const fetchCartListData = async () => {
      try {
        const response = await fetch(localApi + `/clientGroup/allList`);
        const json = await response.json();
        const cList = json.ClientList.filter((f) => f.regId === userObj && userObj.id)[0];

        const list =
          localApi +
          `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj && userObj.admin.id}/${cList.id}?active=0`;
        const result = await fetch(list);
        const data = await result.json();
        setCardList(data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCartListData();
  }, []);

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  async function getBangle() {
    const results = await fetch(
      localApi + `/parent/list/byAdminIdAndCategoryId/2/11`
    );
    const response = await results.json();
    // console.log(response);
    setbangle(response && response);
  }

  useEffect(() => {
    getBangle();
  }, []);

  async function getEaring() {
    const results1 = await fetch(
      localApi + `/parent/list/byAdminIdAndCategoryId/2/12`
    );
    const response = await results1.json();
    setEaring(response && response);
  }

  useEffect(() => {
    getEaring();
  }, []);

  async function getRing() {
    const results2 = await fetch(
      localApi + `/parent/list/byAdminIdAndCategoryId/2/13`
    );
    const response2 = await results2.json();
    setRing(response2 && response2);
  }

  useEffect(() => {
    getRing();
  }, []);

  async function getWatch() {
    const results3 = await fetch(
      localApi + `/parent/list/byAdminIdAndCategoryId/2/14`
    );
    const response3 = await results3.json();
    setWatch(response3 && response3);
  }

  useEffect(() => {
    getWatch();
  }, []);

  async function getKada() {
    const results4 = await fetch(
      localApi + `/parent/list/byAdminIdAndCategoryId/2/15`
    );
    const response4 = await results4.json();
    setKada(response4 && response4);
  }

  useEffect(() => {
    getKada();
  }, []);

  const handleLogOut = () => {
    const finalURL =
      localApi + `/otp/logout/registerById/${userObj && userObj.id}`;
    fetch(finalURL, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.error === true) {
          localStorage.removeItem("sessionData");
          sessionStorage.removeItem("sessionData");
          history.push("/");
          window.location.reload(false);
        }
      });
  };

  return (
    <>
      <List className={classes.list + " " + classes.mlAuto}>
        {/* {" "}
                <div className={classe.mlAuto}>
                    <CustomInput
                        white
                        inputRootCustomClasses={classe.inputRootCustomClasses}
                        formControlProps={{
                            className: classe.formControl,
                        }}
                        inputProps={{
                            placeholder: "Search",
                            // textColor:"white",

                            inputProps: {
                                "aria-label": "Search",
                                className: classe.searchInput,
                                // textColor:"white",
                            },
                        }}
                    />
                    <Button color="white" justIcon round>
                        <Search className={classe.searchIcon} />
                    </Button>
                </div>
                {" "} */}
        {/* {category && category.map((cat) => (
                    <ListItem className={classes.listItem} key={cat.id}>
                        <CustomDropdown
                            noLiPadding
                            navDropdown
                            hoverColor={dropdownHoverColor}
                            buttonText={cat.description}
                            value={simpleSelect}
                            onChange={handleSelect}
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent",
                            }}
                            buttonIcon={Apps}
                            dropdownList={[
                                // <Link to="/" className={classes.dropdownLink}>
                                //     Presentation Page
                                // </Link>,
                            ]}
                        />
                    </ListItem>
                ))} */}

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Bangles"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              bangle &&
              bangle.map((x) => (
                // /bangles-size-mm-list?cat=bangles&size=102
                <Link
                  to={`/bangles-size-mm-list?catdesc=BANGLES&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Earings"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              earing &&
              earing.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=EARRINGS&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Finger Ring"
            // checked={show === "q"}
            // value="p"
            // onChange={() =>setShow("q")}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              ring &&
              ring.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=FINGER RINGS&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Watch"
            // checked={show === "q"}
            // value="p"
            // onChange={() =>setShow("q")}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              watch &&
              watch.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=WATCH&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>

        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Punjabi kada"
            // checked={show === "q"}
            // value="p"
            // onChange={() =>setShow("q")}
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              kada &&
              kada.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=PUNJABI KADA&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Button
            href="/todays-deal"
            className={classes.navLink}
            // onClick={(e) => e.preventDefault()}
            color="transparent"
          >
            Todays Deals
          </Button>
        </ListItem> */}

        {!userSession ? (
          ""
        ) : (
          <>
            {cardList && cardList.length != 0 ? (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={dropdownHoverColor}
                  buttonText="Cart Count"
                  // name="bangles header1"
                  // checked={show === "p"}
                  // value="p"
                  // onChange={() =>setShow("p")}

                  // console.log("Insh")
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                  }}
                  buttonIcon={ShoppingCartIcon}
                  dropdownList={[
                    <Link
                      to={`/add-cart`}
                      // to={`/cart?byuserid=919967457786&clientid=${
                      //     userObj && userObj.mobile.slice(1)
                      //   }&expdate=${date}`}
                      className={classes.dropdownLink}
                    >
                      <AiOutlineAppstoreAdd className={classes.dropdownIcons} />{" "}
                      My Cart
                    </Link>,
                  ]}
                />
              </ListItem>
            ) : (
              ""
            )}

            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={dropdownHoverColor}
                buttonText="Section"
                // name="bangles header1"
                // value="p"
                // checked={show === "p"}
                // onChange={() => setShow("p")}
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                buttonIcon={Apps}
                dropdownList={[
                  <Link to="/edit-profile" className={classes.dropdownLink}>
                    <FaUserEdit className={classes.dropdownIcons} /> Edit
                    Profile
                  </Link>,
                  // <Link to="/edit-password" className={classes.dropdownLink}>
                  //   <FaExpeditedssl className={classes.dropdownIcons} />{" "}
                  //   Generate Password
                  // </Link>,
                  <Link to="/add-address" className={classes.dropdownLink}>
                    <FaAddressBook className={classes.dropdownIcons} /> Add
                    Address
                  </Link>,
                  <Link
                    to="/edit-update-address"
                    className={classes.dropdownLink}
                  >
                    <FaAddressBook className={classes.dropdownIcons} /> Update
                    Address
                  </Link>,
                  <Link to="/todays-deal" className={classes.dropdownLink}>
                    <ShoppingBasketIcon className={classes.dropdownIcons} />
                    Todays Deals
                  </Link>,
                  <Link
                    key="ad"
                    to="/my-order"
                    className={classes.dropdownLink}
                  >
                    <RiShoppingBagLine className={classes.dropdownIcons} /> My
                    Order
                  </Link>,
                  <Link
                    to="/"
                    className={classes.dropdownLink}
                    onClick={handleLogOut}
                  >
                    <FiLogOut className={classes.dropdownIcons} /> Logout
                  </Link>,
                ]}
              />
            </ListItem>
          </>
        )}
      </List>
    </>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
