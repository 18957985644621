/*eslint-disable*/
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import { headApi } from "utils/headApi";
import { localApi } from "utils/headApi";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";
import SectionPolaBangles from "./SectionPolaBangles";
import BanglesContactpage from "./Contact";
import PolaBanglesHeader from "./PolaBanglesHeader";
import PolaBanglesHeaderLinks from "./PolaBanglesHeaderLinks";
import PolaBanglesFooter from "./PolaBanglesFooter";
import PolaBlog from "./PolaBlog";
import PolaPosts from "./PolaPosts";
const useStyles = makeStyles(landingPageStyle);

export default function BanglesLandingpage() {
  const [products, setProducts] = useState([]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const history = useHistory();

  const userSession = JSON.parse(sessionStorage.getItem("sessionData"));
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const handleLogOut = () => {
    const finalURL = localApi + `/otp/logout/registerById/${userObj && userObj.id}`;
    fetch(finalURL, {
      method: "PUT"
      // headers: {
      //   "content-type": "application/json",
      //   accept: "application/json",
      // },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.error === true) {
          sessionStorage.removeItem("sessionData");
          history.push("/")
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // console.log();
      });
  };

  // if (!products) return null;

  const ClickHandler = () => {
    ReactGa.event({
      category: 'Button',
      action: 'Click on the landing page',
      // country: 'India',

    })
    // alert('thank you for visiting the website contact us')
    // console.log(alert);
  }


  const classes = useStyles();
  return (
    <div>

      <Helmet>
        <meta charset="utf-8" />
        <title>Pola Bangles</title>
        <meta name="description" content="Complement your ensembles with this traditional pola bangle set crafted withh a high-polished gold finish. We offer best quality bangles to our customers, with 6 months colour guarantee. Also we use finest quality raw material to make the bangles look charming and elegant. We provide bangles with lowest making charges on order up to 1000 boxes. This is one time benefit, can be only availed at the first order. A Red Pola bangle signifies energy and prosperity, while green Pola denotes good luck and fertility and White Pola Bangles symbolizes peace and happiness." />
        <meta name="keywords" content="Floral Bengali Pola Bangle Set, Pair of Pola Bangles, Pola Bangle with a Gold Stamped Pattern, Gold Shankha Bangle Set of 2, Acrylic & Crystal Pola Bangles Set for Women, Brass Gold Plated & Crystal Pola Bangles Set for Women Pack of 4, Acrylic gold plated shakha pola, Acrylic plastic pola bangles, acrylic  gold plated bracelet, Gold Pola Bandhano, red and white pola bangles, Maharashtrian pola bangles, Odisha pola bangles, Bengal pola bangles, maroon pola bangles, green pola bangles, gold plated pola bangles, loha Sakha pola, sakha pola" />
      </Helmet>
      <PolaBanglesHeader
        // color="white"
        brand="Home"
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "danger",
        }}
      />
      <Parallax image={require("assets/img/TriColorGoldPlated6mmBangles.jpg").default} filter="dark">
        <div className={classes.container}>

          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Pola Bangles </h1>
              <h4>
                Bangles are more than accessories for Indian women.
                It is a tradition to wear Pola Bangles after marriage in various
                states of India like Maharashtra, West Bengal, Odisha, Rajasthan,
                and Gujarat. A Red Pola bangle signifies energy and prosperity,
                while a green Pola denotes good luck and fertility and White Pola
                Bangles symbolize peace and happiness.
              </h4>
              <h4>Click on the link to check our <Link
                color="danger"
                // size="lg"
                href="/#"
              // {`/products-page?id=${prod.id}&catdesc=${prod.cat.description}&cat1desc=${prod.banglessizemm.description}&Plating=${prod.platingList.map((p) => (p.description.slice(8)))}`}
              // target="_blank"

              >

                {/* <i className="fas fa-play" /> */}
                Special offers
              </Link></h4>

              <br />
              <Button
                color="danger"
                // size="lg"
                href="/pola-signup-page"
                // target="_blank"
                onClick={ClickHandler}
              >
                sign up
              </Button>
              {" "}
              {!userSession ?
                <Button
                  color="danger"
                  // size="lg"
                  href="/pola-login-page"
                // target="_blank"
                >
                  {/* <i className="fas fa-play" /> */}
                  log in
                </Button>
                :
                <Button
                  color="danger"
                  onClick={handleLogOut}
                // size="lg"
                // href="/"
                // target="_blank"
                >
                  log out
                </Button>
              }
            </GridItem>
          </GridContainer>

        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionPolaBangles />
          <PolaPosts />
          <div>
            <PolaBlog />
          </div>
        </div>
        <BanglesContactpage />
        {/* <FormEmail/>       */}
      </div>
      <PolaBanglesFooter />
    </div>
  );
}