/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";


// import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
// import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
// import SectionServices from "views/AboutUsPage/Sections/SectionServices.js";
// import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
// import SectionContact from "views/AboutUsPage/Sections/SectionContact.js";
import SectionDescription from "views/MyCompo/AboutUsPage/Sections/SectionDescription";
import SectionOffice from "views/MyCompo/AboutUsPage/Sections/SectionOffice";
import SectionServices from "views/MyCompo/AboutUsPage/Sections/SectionServices";
import SectionTeam from "views/MyCompo/AboutUsPage/Sections/SectionTeam";

import PolaBanglesHeader from 'views/MyCompo/PolaBanglesHeader';
import PolaBanglesHeaderLinks from 'views/MyCompo/PolaBanglesHeaderLinks';
import PolaBanglesFooter from "../PolaBanglesFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import aboutUsStyl from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(aboutUsStyl);

export default function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
                <title>About-Us</title>        
        </Helmet>
      <PolaBanglesHeader
        // color="white"
        brand="Home"        
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" /> }
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "danger",
        }}
        />  
      <Parallax
        image={require("assets/img/bg9.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>About Us</h1>
              <h4 style={{color: "white"}}>
              Manufacturer and Exporter of Gold Plated Brass Bangles.
              www.polabangles.com
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionDescription />
          <SectionTeam />
          <SectionServices />
          <SectionOffice />
          {/* <SectionContact /> */}
        </div>
      </div>
      <PolaBanglesFooter/>
    </div>
  );
}
