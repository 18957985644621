/*eslint-disable*/
import React, { useEffect } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import PolaBanglesHeader from "views/MyCompo/PolaBanglesHeader";
import PolaBanglesHeaderLinks from "views/MyCompo/PolaBanglesHeaderLinks";
import PolaBanglesFooter from "views/MyCompo/PolaBanglesFooter";
// import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import ReturnDescription from "views/MyCompo/ReturnDescription";
// import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
// import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { localApi } from "utils/headApi";
import SweetAlert from "react-bootstrap-sweetalert";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import aboutUsStyl from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import dropDown from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
// import sweetAlertStyle from "assets/jss/material-kit-pro-react/views/sweetAlretStyle.js";
import sweetAlertStyle from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(aboutUsStyl);
const useStyleDrop = makeStyles(dropDown);
const useStyl = makeStyles(sweetAlertStyle);

export default function ReturnAmount() {
  //   React.useEffect(() => {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //   });
  const [orderList, setOrderList] = React.useState([]);
  const [orderDetails, setOrderDetails] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [selectOrder, setSelectOrder] = React.useState("");
  const [selectOrderErr, setSelectOrderErr] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameErr, setLastNameErr] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [companyErr, setCompanyErr] = React.useState("");
  const [invoiceNumber, setInvoiceNumber] = React.useState("");
  const [invoiceNumberErr, setInvoiceNumberErr] = React.useState("");
  const [invoiceDate, setInvoiceDate] = React.useState("");
  const [invoiceDateErr, setInvoiceDateErr] = React.useState("");
  const [ordersId, setOrdersId] = React.useState("");
  const [ordersIdErr, setOrdersIdErr] = React.useState("");
  const [ordersAmount, setOrdersAmount] = React.useState("");
  const [ordersAmountErr, setOrdersAmountErr] = React.useState("");
  const [ordersAdvance, setOrdersAdvance] = React.useState("");
  const [ordersAdvanceErr, setOrdersAdvanceErr] = React.useState("");
  const [totalBox, setTotalBox] = React.useState("");
  const [totalBoxErr, setTotalBoxErr] = React.useState("");
  const [totalCarton, setTotalCarton] = React.useState("");
  const [totalCartonErr, setTotalCartonErr] = React.useState("");
  const [multiCartId, setMultiCartId] = React.useState([]);
  const [btn, setBtn] = React.useState(false);

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const classes = useStyles();
  const classe = useStyle();
  const classeDrop = useStyleDrop();
  const classSweet = useStyl();

  async function getClient() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${userObj && userObj.admin.id}`;
    const result = await fetch(api);
    const getResult = await result.json();
    const cliId = getResult.filter((f) => f.regId == userObj.id)[0];
    setClientId(cliId);

    axios
      .get(
        localApi +
          `/orders/list/byAdminIdAndClientIdAndAfterDate/${
            userObj && userObj.admin.id
          }/${cliId.id}`
      )
      .then((res) => {
        // console.log(res.data)
        setOrderList(res.data.filter((f) => f.cancelOrders != true));

        // if (res.data.length === 0) {
        //   setSubmitted(true);
        //   alertMsgOps("Data Not Found");
        // } else {
        //   setAddCart(res && res.data);
        // }
      });
  }

  useEffect(() => {
    getClient();
  }, []);

  const handleOrders = (event) => {
    const { value } = event.target;
    setSelectOrder(value);
    setSelectOrderErr("");

    const ids = orderList.find((f) => f.id == value);
    setOrderDetails(ids);
    // console.log(ids);
    setMultiCartId(
      ids.ordersCart.map((m) => {
        return m.cartId;
      })
    );
    setFirstName(ids.firstName);
    setLastName(ids.lastName);
    setCompany(ids.company);
    setInvoiceNumber(ids.invoiceNumber);
    setInvoiceDate(ids.dateOfInvoice);
    setOrdersId(ids.id);
    setOrdersAmount(ids.amount);
  };

  const Submit = () => {
    let errorCount = 0;
    if (selectOrder === "" || !selectOrder) {
      errorCount++;
      setSelectOrderErr("Please Select Order Id");
    }
    if (ordersAdvance === "" || !ordersAdvance) {
      errorCount++;
      setOrdersAdvanceErr("Please Enter Advance Amount");
    } else if (
      ordersAdvance != orderDetails.payment.reduce((a, b) => a + b.received, 0)
    ) {
      errorCount++;
      setOrdersAdvanceErr("Your Advance Amount is Not Match");
    }
    if (totalBox === "" || !totalBox) {
      errorCount++;
      setTotalBoxErr("Please Enter Total Box");
    } else if (totalBox != orderDetails.totalBox) {
      errorCount++;
      setTotalBoxErr("Your Total Box is Not Match");
    }
    if (totalCarton === "" || !totalCarton) {
      errorCount++;
      setTotalCartonErr("Please Enter Total Carton");
    } else if (totalCarton != orderDetails.totalCarton) {
      errorCount++;
      setTotalCartonErr("Your Total Carton is Not Match");
    }
    if (errorCount === 0) {
      setBtn(true);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          company: company,
          invoiceNumber: invoiceNumber,
          dateOfInvoice: invoiceDate,
          ordersId: ordersId,
          multiCartId: multiCartId,
          adminId: userObj.admin.id,
          clientId: clientId.id,
          amount: ordersAmount,
          advance: ordersAdvance,
          totalBox: totalBox,
          totalCarton: totalCarton,
        }),
      };
      fetch(localApi + `/cancel/add`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data.message === "Data Inserted Successfully !!!") {
            // alertTypeOps("success");
            // alertMsgOps("Succesfully !!!");
            // setSubmitted(true);
            axios
              .put(
                localApi +
                  `/orders/update/byAdminAndOrderIdForCancelOrders/${userObj.admin.id}/${ordersId}`
              )
              .then((res) => {
                console.log(res.data);
                if (res.data.message === "Data Updated Successfully !!!") {
                  alertTypeOps("success");
                  alertMsgOps("Succesfully !!!");
                  setSubmitted(true);
                }
              });
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div>
      <Helmet>
        <title>Return Policy</title>
        <meta
          name="description"
          content="Pola Bangles Return Policy If you are not satisfied with your purchase, you can return the product and get a full refund or exchange the product for another one, be it similar or 
          Product return will be accepted in case of damage on receipt or difference in product design from that on the website.
          "
        />
        <meta name="keywords" content="Pola Bangles Return Policy" />
      </Helmet>
      <PolaBanglesHeader
        brand="Home"
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "danger",
        }}
      />
      <Parallax
        image={require("assets/img/bg2.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={10}
              sm={10}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1
                style={{
                  color: "#fff",
                  fontFamily: "Tahoma",
                  fontWeight: "bold",
                }}
              >
                Return Policy
              </h1>
              <h4 style={{ color: "#fff" }}>
                Your satisfaction important to us...
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center" alignItems="center">
            <GridItem
              xs={12}
              sm={4}
              md={4}
              style={{ marginTop: "3%", marginBottom: "2%" }}
            >
              <FormControl fullWidth className={classeDrop.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select-order"
                  className={classeDrop.selectLabel}
                >
                  Select Orders
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classeDrop.selectMenu,
                  }}
                  classes={{
                    select: classeDrop.select,
                  }}
                  value={selectOrder}
                  onChange={handleOrders}
                  required={true}
                  inputProps={{
                    name: "simpleSelectOrder",
                    id: "simple-select-order",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classeDrop.selectMenuItem,
                    }}
                  >
                    Select Orders
                  </MenuItem>
                  {orderList.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      classes={{
                        root: classeDrop.selectMenuItem,
                        selected: classeDrop.selectMenuItemSelected,
                      }}
                    >
                      {item.invoiceNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span style={{ color: "red", marginLeft: "12px" }}>
                {selectOrderErr}
              </span>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {submitted ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "100px" }}
                title={alertMsg}
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={
                  classSweet.button + " " + classSweet.success
                }
              >
                Inserted Your Return
              </SweetAlert>
            ) : null}
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                labelText="First Name"
                id="firstName"
                inputProps={{
                  placeholder: "First Name...",
                  name: "firstName",
                  disabled: true,
                  value: firstName,
                  onChange: (e) => {
                    setFirstName(e.target.value);
                    setFirstNameErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {firstNameErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                labelText="Last Name"
                id="lastName"
                inputProps={{
                  placeholder: "Last Name...",
                  name: "lastName",
                  disabled: true,
                  value: lastName,
                  onChange: (e) => {
                    setLastName(e.target.value);
                    setLastNameErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {lastNameErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                labelText="Company"
                id="company"
                inputProps={{
                  placeholder: "Company...",
                  name: "company",
                  disabled: true,
                  value: company,
                  onChange: (e) => {
                    setCompany(e.target.value);
                    setCompanyErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {companyErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                labelText="Invoice Number"
                id="invoiceNumber"
                inputProps={{
                  placeholder: "Invoice Number...",
                  name: "invoiceNumber",
                  disabled: true,
                  value: invoiceNumber,
                  onChange: (e) => {
                    setInvoiceNumber(e.target.value);
                    setInvoiceNumberErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {invoiceNumberErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                labelText="Invoice Date"
                id="invoiceDate"
                inputProps={{
                  placeholder: "Invoice Date...",
                  name: "invoiceDate",
                  disabled: true,
                  value: invoiceDate,
                  onChange: (e) => {
                    setInvoiceDate(e.target.value);
                    setInvoiceDateErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {invoiceDateErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <CustomInput
                labelText="Orders Id"
                id="ordersId"
                inputProps={{
                  placeholder: "Orders Id...",
                  name: "ordersId",
                  value: ordersId,
                  disabled: true,
                  onChange: (e) => {
                    setOrdersId(e.target.value);
                    setOrdersIdErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {ordersIdErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <CustomInput
                labelText="Amount"
                id="ordersAmount"
                inputProps={{
                  placeholder: "amount...",
                  name: "ordersAmount",
                  disabled: true,
                  value: ordersAmount,
                  onChange: (e) => {
                    setOrdersAmount(e.target.value);
                    setOrdersAmountErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {ordersAmountErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <CustomInput
                labelText="Advance"
                id="ordersAdvance"
                inputProps={{
                  placeholder: "Advance...",
                  name: "ordersAdvance",
                  value: ordersAdvance,
                  onChange: (e) => {
                    setOrdersAdvance(e.target.value);
                    setOrdersAdvanceErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {ordersAdvanceErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <CustomInput
                labelText="Total Box"
                id="totalBox"
                inputProps={{
                  placeholder: "Total Box...",
                  name: "totalBox",
                  value: totalBox,
                  onChange: (e) => {
                    setTotalBox(e.target.value);
                    setTotalBoxErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {totalBoxErr}
              </span>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <CustomInput
                labelText="Total Carton"
                id="totalCarton"
                inputProps={{
                  placeholder: "Total Carton...",
                  name: "totalCarton",
                  value: totalCarton,
                  onChange: (e) => {
                    setTotalCarton(e.target.value);
                    setTotalCartonErr("");
                  },
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
              <span style={{ color: "red", fontSize: "13px" }}>
                {totalCartonErr}
              </span>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            {btn === true ? (
              <Button round>Submit</Button>
            ) : (
              <Button color="danger" onClick={Submit}>
                Submit
              </Button>
            )}
          </GridContainer>
          <br />
        </div>
      </div>
      <PolaBanglesFooter />
    </div>
  );
}
