/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import PolaBanglesHeader from 'views/MyCompo/PolaBanglesHeader';
import PolaBanglesHeaderLinks from 'views/MyCompo/PolaBanglesHeaderLinks';
import PolaBanglesFooter from "views/MyCompo/PolaBanglesFooter";
// import SectionDescription from "views/AboutUsPage/Sections/SectionDescription.js";
import PrivacyDescription from "views/MyCompo/PrivacyDescription";
// import SectionTeam from "views/AboutUsPage/Sections/SectionTeam.js";
// import SectionOffice from "views/AboutUsPage/Sections/SectionOffice.js";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import aboutUsStyl from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(aboutUsStyl);

export default function PrivacyPolicy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="Pola Bangles This Privacy Policy describes how your personal information is collected, used, and save with us When you purchase from us, we'll ask you to provide information including your name, billing address, shipping address, email address, phone number, credit card, To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused."
        />
        <meta name="keywords" content="Pola Bangles Privacy Policy" />
      </Helmet>
      <PolaBanglesHeader
        brand="Home"
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "danger",
        }}
      />
      <Parallax
        image={require("assets/img/bg2.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={10}
              sm={10}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 style={{color: "#fff", fontFamily: "Tahoma", fontWeight: "bold"}}>Privacy Policy</h1>
              <h4 style={{color: "#fff"}}>
              Your satisfaction important to us...
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <PrivacyDescription />
          {/* <SectionTeam /> */}
          {/* <SectionOffice /> */}
          <br />
        </div>
      </div>
      <PolaBanglesFooter />
    </div>
  );
}