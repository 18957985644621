/*eslint-disable*/
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { localApi } from "utils/headApi";
import Popover from "@material-ui/core/Popover";
import Button from "components/CustomButtons/Button.js";
import axios from "axios";
import { adminId } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";
import gucci from "assets/img/examples/gucci.jpg";
import logo from "assets/img/examples/apple-pola-bangles-icon.png";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

const useStyles = makeStyles(styles);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MakingDetailsPola() {
  const [products, setProducts] = useState([]);
  const [page, setpage] = React.useState(0);
  const [loginModal, setLoginModal] = React.useState(false);
  const [anchorElBottom, setAnchorElBottom] = React.useState(null);
  const [prodDetails, setProdDetails] = React.useState("");
  const [prodDetailsChild, setProdDetailsChild] = React.useState("");
  const [prodDetailsChild1, setProdDetailsChild1] = React.useState("");
  const [prodDetailsChild2, setProdDetailsChild2] = React.useState("");
  const [prodDetailsRaw, setProdDetailsRaw] = React.useState("");
  const [prodDetailsSets, setProdDetailsSets] = React.useState("");
  const [prodDetailsBox, setProdDetailsBox] = React.useState("");

  const classes = useStyles();
  const classPop = useStylPop();

  const fetchData = async () => {
    setpage(page + 1);
    axios
      .get(localApi + `/product/list/byLimit?adminId=2&page=${page}&size=4`)
      .then((res) => setProducts([...products, ...res.data]));
  };

  useEffect(() => {
    fetchData();
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  var lbrprice = 0;
  var boxlbrprice = 0;
  var metamt = 0;
  var metprice = 0;
  var boxmetgram = 0;
  var metgram = 0;
  var pltprice = 0;
  var boxpltamt = 0;
  var metgramamt = 0;

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <Dialog
          style={{ marginTop: "10%" }}
          classes={{
            root: classPop.modalRoot,
            paper: classPop.modal + " " + classPop.modalLogin,
          }}
          open={loginModal}
          TransitionComponent={Transition}
          keepMounted
          // onClose={() => setLoginModal(false)}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          <DialogTitle
            id="login-modal-slide-title"
            disableTypography
            className={classPop.modalHeader}
          >
            <h5>Loading...</h5>
            <DialogContent
              id="login-modal-slide-description"
              className={classPop.modalBody}
            >
              <GridContainer justify="center">
                <Spinner
                  onChange={() => hideAlertPop()}
                  style={{ color: "#f44336" }}
                />
              </GridContainer>
              <br />
            </DialogContent>
          </DialogTitle>
        </Dialog>
        <h2>Making Details</h2>
        <InfiniteScroll
          dataLength={products.length}
          next={fetchData}
          hasMore={true}
        >
          <div className={classes.container}>
            <GridContainer>
              {products &&
                products.map((prod, index) => {
                  {
                    lbrprice = 0;
                  }
                  {
                    boxlbrprice = 0;
                  }
                  {
                    metamt = 0;
                  }
                  {
                    metprice = 0;
                  }
                  {
                    boxmetgram = 0;
                  }
                  {
                    metgram = 0;
                  }
                  {
                    pltprice = 0;
                  }
                  {
                    boxpltamt = 0;
                  }
                  {
                    metgramamt = 0;
                  }
                  // pltprice = pltprice + makd.plating.price
                  boxpltamt = prod.productSets.pcs * prod.productBox.box;
                  metprice = metprice + prod.productRawPrice.price;
                  metgram = prod.metalGram;
                  metamt =
                    prod.productRawPrice.price + prod.productRawPrice.different;
                  boxmetgram =
                    metgram *
                    prod.productSets.pcs *
                    prod.productBox.box *
                    metamt;
                  metgramamt = metgram * metamt;
                  return (
                    <>
                      <GridItem md={3} sm={6} key={prod.id}>
                        <Card product plain>
                          <CardHeader image plain>
                            <img
                              src={
                                prod.productImage[0].cropImageUrl +
                                "?id=" +
                                prod.productImage[0].imgId
                              }
                              alt="..."
                            />
                            {/* <a href="#pablo">
                        {makd.design1.img1active == 1 ? <img src={imgApi + "" + makd.design1.image1} alt="polabangles" /> :
                          makd.design2.img2active == 1 ? <img src={imgApi + "" + makd.design2.image2} alt="polabangles" /> :
                            makd.design3.img3active == 1 ? <img src={imgApi + "" + makd.design3.image3} alt="polabangles" /> :
                              makd.design4.img4active == 1 ? <img src={imgApi + "" + makd.design4.image4} alt="polabangles" /> :
                                makd.design5.img5active == 1 ? <img src={imgApi + "" + makd.design5.image6} alt="polabangles" /> :
                                  makd.design6.img6active == 1 ? <img src={imgApi + "" + makd.design6.image6} alt="polabangles" /> : " "}
                      </a> */}
                            <p
                              style={{
                                position: "absolute",
                                top: "65%",
                                left: "50%",
                                width: "60px",
                                height: "60px",
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <img src={logo} alt="..." />
                              {/* {imgApi + "" + makd.design1.image1 !==
                          imgApi + "" + "/crop_img/null" ? (
                          <img src={logo} alt="..." />
                        ) : imgApi + "" + makd.design2.image2 !==
                          imgApi + "" + "/crop_img/null" ? (
                          <img src={logo} alt="..." />
                        ) : imgApi + "" + makd.design3.image3 !==
                          imgApi + "" + "/crop_img/null" ? (
                          <img src={logo} alt="..." />
                        ) : imgApi + "" + makd.design4.image4 !==
                          imgApi + "" + "/crop_img/null" ? (
                          <img src={logo} alt="..." />
                        ) : imgApi + "" + makd.design5.image5 !==
                          imgApi + "" + "/crop_img/null" ? (
                          <img src={logo} alt="..." />
                        ) : imgApi + "" + makd.design6.image6 !==
                          imgApi + "" + "/crop_img/null" ? (
                          <img src={logo} alt="..." />
                        ) : (
                          ""
                        )} */}
                            </p>
                            <div
                              className={classes.coloredShadow}
                              style={{
                                backgroundImage: `url(${gucci})`,
                                opacity: 1,
                              }}
                            />
                          </CardHeader>
                          <CardBody
                            style={{ textAlign: "left" }}
                            className={classes.textCenter}
                            plain
                          >
                            <center>
                              <h4 className={classes.cardTitle}>
                                {prod.productCategory.title} <br />{" "}
                                {prod.productParent.title} {prod.itemCodeNumber}{" "}
                                - {prod.id}<br />
                                <Button
                                    round
                                    color="danger"
                                    onClick={(event) => {
                                      setAnchorElBottom(event.currentTarget);
                                      setProdDetails(prod);
                                      setProdDetailsChild(
                                        prod.productChild.map((lbr) => {
                                          return (
                                            <>
                                              <span>
                                                {lbr.title + " " + lbr.price}
                                              </span>
                                              <br />
                                            </>
                                          );
                                        })
                                      );
                                      setProdDetailsChild1(
                                        prod.productChild
                                          .reduce((a, b) => a + b.price, 0)
                                          .toFixed(2)
                                      );
                                      setProdDetailsChild2(
                                        prod.productChild
                                          .reduce(
                                            (a, b) =>
                                              a +
                                              b.price *
                                                prod.productSets.pcs *
                                                prod.productBox.box,
                                            0
                                          )
                                          .toFixed(2) + "/-"
                                      );
                                      setProdDetailsRaw(prod.productRawPrice);
                                      setProdDetailsSets(prod.productSets);
                                      setProdDetailsBox(prod.productBox);
                                    }}
                                  >
                                    More Details
                                  </Button>
                              </h4>
                            </center>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </>
                  );
                })}
                <Popover
                    classes={{
                      paper: classPop.popover,
                    }}
                    open={Boolean(anchorElBottom)}
                    anchorEl={anchorElBottom}
                    onClose={() => setAnchorElBottom(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className={classPop.popoverBody}>
                      <span style={{ color: "yellow" }}>
                        Product Id: {prodDetails.id}
                      </span>
                      <br />
                      {prodDetailsChild}
                      <span style={{ color: "yellow" }}>
                        Labour/Box. {prodDetailsChild1}
                        {"/"}
                        {prodDetailsChild2}
                      </span>
                      <br />
                      <span>
                        Metal Price {prodDetailsRaw.price} +{" "}
                        {prodDetailsRaw.different} = {metamt} <br />
                        Metal Gram {prodDetails.metalGram} <br />
                        Ready Gram {prodDetails.readyGram}
                      </span>
                      <br />
                      <div className={classes.cardDescriptions}>
                        {(prodDetails.readyGram * prodDetailsSets.pcs).toFixed(
                          4
                        )}{" "}
                        Avg Gram Of {prodDetailsSets.setDesc}{" "}
                        {prodDetailsBox.boxDesc}
                      </div>{" "}
                      <span style={{ color: "yellow" }}>
                        Metal Amount{" "}
                        {metgramamt.toFixed(2) + "/" + boxmetgram.toFixed(2)}
                        /-
                      </span>
                    </div>
                  </Popover>
            </GridContainer>
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}
