/*eslint-disable*/
import React, { useState, useEffect, useStyles } from 'react';
import ReactImageProcess from 'react-image-process';
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Favorite from "@material-ui/icons/Favorite";
import IconButton from '@material-ui/core/IconButton';
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.js";
import gucci from "assets/img/examples/gucci.jpg";
import QWE from "assets/img/examples/QWE.jpg";
import ASD from "assets/img/examples/ASD.jpg";
import ZXC from "assets/img/examples/ZXC.jpg";
import MNP from "assets/img/examples/MNP.jpg";
import JKL from "assets/img/examples/JKL.jpg";
import logo from "assets/img/examples/apple-pola-bangles-icon.png"
import Cached from "@material-ui/icons/Cached";
import Subject from "@material-ui/icons/Subject";
import Check from "@material-ui/icons/Check";
import { headApi } from 'utils/headApi';
import { adminId } from 'utils/headApi';
import { imgApi } from 'utils/imgApi';
// import logo from "assets/img/examples/apple-insha-bangles-icon.png";


export default function PolaBanglesOffers() {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  // const userSession = JSON.parse(sessionStorage.getItem("sessionData"));
  // const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const [products, setProducts] = useState([]);

  const [isloading, setLoading] = useState(false);

  const activeSession = sessionStorage.getItem("sessionData");

  function sayHi() {
    console.log("Hello!");
  }

  async function getProducts() {
    setLoading(true);
    const api = headApi + `/products?userid=${adminId}&active=0`;
    const result = await fetch(api);
    const getResult = await result.json();
    setProducts(getResult.ProductsData);
  }

  useEffect(() => {
    getProducts();
    setLoading(true);
  }, []);

  const secure = "../../secure/crop_img/0608201902.jpg";
  const design_img = "../../design_img/0708201901.jpg";

  const LoadMore = () => {
    if (isloading) {
      return
    }

  }

  var sum = 0;
  var p = 0;
  var m;
  var n;

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {/* <h2>News in fashion</h2> */}

        <GridContainer>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${QWE})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Apps */}
                </h6>
                <a href="/bangles-product?id=277&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>
                    RED & WHITE POLA BANGLES
                  </h3>
                </a>
                <p className={classes.description}>
                  Each of our pieces made with good quality raw material and polished with
                  fine gold plating that brings out the hidden beauty around you.
                </p>
                <Button href="/bangles-product?id=277&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated" round color="danger">
                  <Subject /> Read
                </Button>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=277&size=5MM&cat=POLA&app_absent=0" target="_blank"> <IconButton style={{ color: "white", fontSize: "40px", backgroundColor: "#42d429", height: "35px", width: "35px" }} onPress={sayHi}>
                  <i className="fab fa-whatsapp" />
                </IconButton> </a>
              </CardBody>
            </Card>

          </GridItem>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${ASD})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* FASHION NEWS */}
                </h6>
                <a href="/bangles-product?id=386&catdesc=Bangles&cat1desc=8MM&Plating=3tone">
                  <h3 className={classes.cardTitle}>
                    3 TONE BANGLES
                  </h3>
                </a>
                <p className={classes.description}>
                  Come in and find out why we have the biggest collection of Bangles,
                  You won’t find a better deal anywhere else.
                </p>
                <Button href="/bangles-product?id=386&catdesc=Bangles&cat1desc=8MM&Plating=3tone" round color="danger">
                  <Subject /> Read
                </Button>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=386&size=5MM&cat=POLA&app_absent=0" target="_blank"> <IconButton style={{ color: "white", fontSize: "40px", backgroundColor: "#42d429", height: "35px", width: "35px" }} onPress={sayHi}>
                  <i className="fab fa-whatsapp" />
                </IconButton> </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={4} sm={4}>
            <Card background style={{ backgroundImage: `url(${ZXC})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Apps */}
                </h6>
                <a href="/bangles-product?id=293&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>
                    KANYASHREE KADA
                  </h3>
                </a>
                <p className={classes.description}>
                  All our bangles are easy on your budget, when you need a
                  sparkle of brilliance or a touch of shine, we’re here to help.
                </p>
                <Button href="/bangles-product?id=293&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated" round color="danger">
                  <Subject /> Read
                </Button>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=293&size=6MM&cat=POLA&app_absent=0" target="_blank"> <IconButton style={{ color: "white", fontSize: "40px", backgroundColor: "#42d429", height: "35px", width: "35px" }} onPress={sayHi}>
                  <i className="fab fa-whatsapp" />
                </IconButton> </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${MNP})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Tutorials */}
                </h6>
                <a href="/bangles-product?id=398&catdesc=Bangles&cat1desc=12MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>ODISHA POLA BANGLES</h3>
                </a>
                <p className={classes.description}>
                  For women and Girls looking for something special to commemorate
                  a cherished occasion, These are sure to fit in perfectly with your style

                </p>
                <Button href="/bangles-product?id=398&catdesc=Bangles&cat1desc=12MM&Plating=Gold%20Plated" round color="danger">
                  <Subject /> Read
                </Button>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=398&size=12MM&cat=POLA&app_absent=0" target="_blank"> <IconButton style={{ color: "white", fontSize: "40px", backgroundColor: "#42d429", height: "35px", width: "35px" }} onPress={sayHi}>
                  <i className="fab fa-whatsapp" />
                </IconButton> </a>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem md={6} sm={6}>
            <Card background style={{ backgroundImage: `url(${JKL})` }}>
              <CardBody background>
                <h6
                  className={classNames(classes.cardCategory, classes.textInfo)}
                >
                  {/* Productivity Style */}
                </h6>
                <a href="/bangles-product?id=351&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated">
                  <h3 className={classes.cardTitle}>MAHARASHTRIAN GREEN POLA BANGLES</h3>
                </a>
                <p className={classes.description}>
                  Everyday styled bangles, fancy and party wear bangles in your budget. You can choose
                  the type of bangles design you would like to wear as it comes in different styles and fashion.
                </p>
                <Button href="/bangles-product?id=351&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated" round color="danger">
                  <Subject /> Read
                </Button>
                <a href="https://api.whatsapp.com/send/?phone=919967457786&text=https://polabangles.com/bangles-product?id_no=351&size=5MM&cat=POLA&app_absent=0" target="_blank"> <IconButton style={{ color: "white", fontSize: "40px", backgroundColor: "#42d429", height: "35px", width: "35px" }} onPress={sayHi}>
                  <i className="fab fa-whatsapp" />
                </IconButton> </a>
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>


        <h2>Popular All items Today</h2>
        <GridContainer>
          {products &&
            products.map((prod) => (
              <GridItem md={3} sm={6} key={prod.id}>
                <Card product plain>
                  <CardHeader image plain>
                    {/* <a href="#pablo"> */}
                    {/* <img src={gucci} alt="..." /> */}
                    {prod.design1.img1active === 1 ? (
                      <img
                        src={imgApi + "" + prod.design1.image1}
                        alt="..."
                      />
                    ) : prod.design2.img2active === 1 ? (
                      <img
                        src={imgApi + "" + prod.design2.image2}
                        alt="..."
                      />
                    ) : prod.design3.img3active === 1 ? (
                      <img
                        src={imgApi + "" + prod.design3.image3}
                        alt="..."
                      />
                    ) : prod.design4.img4active === 1 ? (
                      <img
                        src={imgApi + "" + prod.design4.image4}
                        alt="..."
                      />
                    ) : prod.design5.img5active === 1 ? (
                      <img
                        src={imgApi + "" + prod.design5.image5}
                        alt="..."
                      />
                    ) : prod.design6.img6active === 1 ? (
                      <img
                        src={imgApi + "" + prod.design6.image6}
                        alt="..."
                      />
                    ) : (
                      ""
                    )}
                    <p
                      style={{
                        position: "absolute",
                        top: "65%",
                        left: "50%",
                        width: "60px",
                        height: "60px",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {imgApi + "" + prod.design1.image1 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design2.image2 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design3.image3 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design4.image4 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design5.image5 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : imgApi + "" + prod.design6.image6 !==
                        imgApi + "" + "/crop_img/null" ? (
                        <img src={logo} alt="..." />
                      ) : (
                        ""
                      )}
                    </p>
                    {/* </a> */}
                    <div
                      className={classes.coloredShadow}
                      style={{ backgroundImage: `url(${gucci})`, opacity: 1 }}
                    />
                  </CardHeader>
                  <CardBody className={classes.textCenter} plain>
                    <h5 className={classes.cardTitle}>
                      {prod.cat.description} {prod.banglessizemm.description}

                      {" - " + prod.itemcode}
                    </h5>
                    <p className={classes.cardDescription}>
                      {prod.description}{" "}
                      {(prod.metalOption.readygram * prod.sets.pcs).toFixed(4)}{" "}
                      Avg Gram Of {prod.sets.setdesc} and {prod.box.boxdesc}{"."}<br />
                      Minimum Order Quantity: {prod.taxCalculation.moq + " Boxes"} <br />
                      {!activeSession ? <h3 style={{ color: "black" }}>
                        Price{" "}
                        <span>
                          ₹{" "}
                          {(
                            prod.taxCalculation.cash / prod.box.box
                          ).toFixed(2)}
                          /-*
                        </span>
                      </h3> : <h3 style={{ color: "black" }}>
                        Price{" "}
                        <span>
                          ₹{" "}
                          {(
                            prod.taxCalculation.cash / prod.box.box
                          ).toFixed(2)}
                          /-*
                        </span>
                      </h3>}
                    </p>
                  </CardBody>
                  {/* <CardFooter className={classes.justifyContentBetween}>
                    <div className={classes.price}>
                      <Button
                        color="danger"
                        href={`/bangles-product?id=${prod.id}&catdesc=${
                          prod.cat.description
                        }&cat1desc=${
                          prod.banglessizemm.description
                        }&Plating=${prod.plating.description.slice(8)}`}
                      >
                        Buy Now
                      </Button>
                    </div>
                    <div>
                      <a
                        href={`https://api.whatsapp.com/send/?phone=${prod.user.whatsapp}&text=https://polabangles.com/bangles-product?id=${prod.id}&app_absent=0`}
                        target="_blank"
                      >
                        {" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IconButton
                          style={{
                            color: "white",
                            fontSize: "40px",
                            backgroundColor: "#42d429",
                            height: "37px",
                            width: "37px",
                          }}
                        >
                          <i className="fab fa-whatsapp" />
                        </IconButton>
                      </a>
                      <Button color="success" round justIcon href="https://api.whatsapp.com/send/?phone=919967457786&text=https://bangles.org.in/bangles-product?product_no=101&size=5MM&cat=POLA&app_absent=0" >
                         <i className="fab fa-whatsapp" />
                     </Button>
                    </div>
                  </CardFooter> */}
                </Card>
              </GridItem>
            ))}
        </GridContainer>
        {products ?
          <h4><b>Bangles with the best standard</b> :- We are one of the top manufacturers and retailers in India, having hundreds of satisfied and happy clients from India and around the globe. We manufacture bangles of different models, sizes, and colors like Micro gold plated Rose gold, silver plated, 2 tone & 3 tone 7Seminario bangles at very affordable low prices.</h4> : ""}
        <GridItem
          md={3}
          sm={3}
          className={classNames(classes.mlAuto, classes.mrAuto)}
        >
          <Button round color="danger" onClick={LoadMore}>
            Load more...
          </Button>
        </GridItem>
      </div>
    </div>

    // </div>

  );
}
