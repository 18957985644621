/*eslint-disable*/
import React, { useEffect } from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import PolaBanglesHeader from "./PolaBanglesHeader";
import PolaBanglesHeaderLinks from "./PolaBanglesHeaderLinks";
import PolaBanglesFooter from "./PolaBanglesFooter";
import MakingDetailsPola from "./MakingDetailsPola";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress"
import Slide from "@material-ui/core/Slide";

import styles from "assets/jss/material-kit-pro-react/views/ecommerceStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(aboutUsStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MakingDetails() {

  const userSession = JSON.parse(sessionStorage.getItem("sessionData"));

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Making-Details</title>
        <meta name="description" content="Making charges vary depending on the type of gold bangles you are buying. This is because every bangle requires a different style of cutting and finishing. It also depends on how much fine detailing is required in the design, i.e., if it is man-made or machine-made. A machine-made bangle usually costs less than a man-made one." />
        <meta name="keywords" content="lowest making charges on bangles, zero making charges, making charges on live metal rate,  making charges in polabangles.com, making charges of imitation bangles, brass bangles making charges, making charges on gold plated bangles, how to calculate making charges on bangles, how to know the making charges of brass bangles, offers on making charges, what is normal making charges for brass bangles, benefits on making charges, discount on making charges on online purchase, what are making charges, flat rate making charges" />
      </Helmet>

      <PolaBanglesHeader
        brand="Home"
        links={<PolaBanglesHeaderLinks dropdownHoverColor="danger" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "danger",
        }}
      />
      <Parallax
        image={require("assets/img/examples/clark-street-merc.jpg").default}
        filter="dark"
        small
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>Making List</h1>
              <h4 style={{ color: "white" }}>Each POLA{"'"}S piece is crafted to give it a signature finish. Yet, you get a wide range of gold-plated jewellery choices with the making charges at a good price.</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      
      <div className={classNames(classes.main, classes.mainRaised)}>
        < MakingDetailsPola />
      </div>
      <PolaBanglesFooter />
    </div>
  );
}
